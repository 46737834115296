package org.ecolution.webpage.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.components.layouts.HalfPageContentStyle
import org.ecolution.webpage.components.widgets.PartnerGrid
import org.ecolution.webpage.components.widgets.SubTitle
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Partner.Companion.ESG.ESG_SUBTITLE
import org.ecolution.webpage.data.Partner.Companion.ESG.esg
import org.ecolution.webpage.data.Partner.Companion.ESG.esgWithTitle
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.dom.Img


@Composable
fun ESGSection() {
    val breakpoint = rememberBreakpoint()

    if (breakpoint > Breakpoint.MD) {
        ESGSectionLarge()
    } else {
        ESGSectionSmall()
    }
}

@Composable
private fun ESGSectionLarge() {
    Box(
        modifier = HalfPageContentStyle.toModifier(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            SubTitle(ESG_SUBTITLE, Modifier.margin(top=3.em, bottom = 1.em))
            SimpleGrid(
                numColumns(2, md = 3, lg = 5),
                Modifier.fillMaxWidth()
            ) {
                esg.forEach { entry ->
                    PartnerGrid(entry, false)
                }
            }
            EsgTitle(height = 5)
        }
    }
}

@Composable
private fun ESGSectionSmall() {
    Box(
        modifier = HalfPageContentStyle.toModifier(),
        contentAlignment = Alignment.Center
    ) {
        Column {
            SubTitle(ESG_SUBTITLE, Modifier.margin(topBottom =1.em))
            SimpleGrid(
                numColumns(2, md = 3),
                Modifier.margin(top = 1.cssRem).fillMaxWidth()
            ) {
                esgWithTitle.forEach { entry ->
                    PartnerGrid(entry, false)
                }
            }
            EsgTitle(height = 5)
        }
    }
}

@Composable
private fun EsgTitle(height: Int) {
    val colorMode by ColorMode.currentState
    val breakpoint = rememberBreakpoint()

    if (breakpoint > Breakpoint.MD) {

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .margin(top = 2.cssRem)
        ) {
            Anchor(Company.Eco.ESG_URL, content = {
                Img(
                    src = getImageSource(colorMode),
                    alt = "ESG Sustainability Development Goals",
                    Modifier
                        .height(height.cssRem)
                        .display(DisplayStyle.Block)
                        .toAttrs()
                )
            })
        }
    }
}

private fun getImageSource(colorMode: ColorMode) = when (colorMode) {
    ColorMode.DARK -> Company.Eco.ESG_TITLE_DARK
    ColorMode.LIGHT -> Company.Eco.ESG_TITLE_LIGHT
}

