package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.ServiceLayout
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.data.Service.Companion.SubNavigation.MINERGIE_ANKER
import org.ecolution.webpage.data.Service.Companion.SubNavigation.MINERGIE_IMG
import org.ecolution.webpage.data.Service.Companion.SubNavigation.MINERGIE_NAME

@Page
@Composable
fun MinergienachweisPage() {
    ServiceLayout(
        serviceName = MINERGIE_NAME,
        subtitle = SUBTITLE,
        imgsrc = MINERGIE_IMG,
        marketing = MARKETING,
        faqs = FAQS,
        faqAnkerId = MINERGIE_ANKER,
        buttons = BUTTONS,
        keyFacts = KEYFACTS
    )
}

private const val SUBTITLE = "nach SIA 380/1"

private val MARKETING = listOf(
    "Spitzenleistungen in puncto Energieeffizienz und Wohnkomfort",
    "Einfache und komplett digitale Abwicklung",
    "Garantierte Qualität und Kostentransparenz"
)

private val KEYFACTS = listOf(
    KeyFact(
        imgSrcLightMode = Company.Eco.ENERGY_LIGHT,
        imgSrcDarkMode = Company.Eco.ENERGY_DARK,
        title = "Maximale Energieeffizienz mit Minergie",
        "Entdecken Sie mit unserer Minergie-Zertifizierung, wie Ihr Gebäude zu einem Vorreiter in Sachen " +
                "Energieeffizienz werden kann. Wir reduzieren nicht nur Ihren ökologischen Fussabdruck, sondern auch " +
                "Ihre Energiekosten – für ein nachhaltigeres Morgen."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.INCREASE_LIGHT,
        imgSrcDarkMode = Company.Eco.INCREASE_DARK,
        title = "Bonus bei Ausnützungsziffer – Mehr Raum für Ihre Visionen",
        description = "Bei bestimmten Projekten können Sie sich über einen Bonus bei der Ausnützungsziffer freuen. Dies bedeutet," +
                " dass Sie in manchen Gemeinden die Möglichkeit haben, mehr Wohnfläche als üblich zu realisieren. " +
                "Ein entscheidender Vorteil, der Ihre Projektvisionen erweitert und gleichzeitig die Umwelt schont. " +
                "Lassen Sie uns gemeinsam die Möglichkeiten für Ihr Projekt bei Ecolution erkunden."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.CASH_ADDON_LIGHT,
        imgSrcDarkMode = Company.Eco.CASH_ADDON_DARK,
        title = "Komplettservice für Fördergeld-Beantragung",
        description = "Als Zusatzleistung kann Ecolution für Sie den gesamten Antragsprozess für Fördergelder " +
                "übernehmen. Wir erledigen die Beantragung und sämtliche Formalitäten, um Ihnen finanzielle " +
                "Unterstützung für Ihr Energieeffizienzprojekt zu sichern. Mit diesem Service bieten wir eine " +
                "umfassende und sorgenfreie Lösung für Ihre Investition."
    )
)

private val BUTTONS = listOf(
    //Triple("", "TBD", false),
    //Triple("", "TBD", false),
    Triple(MINERGIE_ANKER, "FAQ", true)
)


private val FAQS = listOf(
    FAQ(
        "Warum sollte ich eine Minergie Zertifizierung anstreben?",
        "Eine Minergie-Zertifizierung erhöht den Wert Ihrer Immobilie, verbessert  den Wohnkomfort und trägt " +
                "zum Klimaschutz bei. Sie bietet auch Zugang  zu Fördermitteln und kann die Kapital- und " +
                "Betriebskosten langfristig  senken."
    ),
    FAQ(
        "Was ist Minergie Zertifizierung?",
        "Minergie ist ein Schweizer Standard für nachhaltiges Bauen, der hohe  Anforderungen an Komfort, " +
                "Energieeffizienz und Klimaschutz stellt. Er  gilt sowohl für Neubauten als auch für Sanierungen und " +
                "umfasst  verschiedene Standards wie Minergie, Minergie-P, Minergie-A und weitere."
    ),
    FAQ(
        "Welche Dienstleistungen bietet Ecolution im Rahmen der Minergie Zertifizierung?",
        "Ecolution unterstützt Sie bei der Bewertung, Planung und  Umsetzung von Minergie-Projekten. " +
                "Dies beinhaltet die Energiebewertung  Ihres Gebäudes, die Beratung bei der Sanierung, die Antragstellung " +
                "und  Unterstützung bei der Beantragung von Fördergeldern."
    ),
    FAQ(
        "Welche Minergie-Standards gibt es und was bedeuten sie?",
        "Neben dem Basistandard Minergie gibt es Minergie-P für besonders  niedrigen Energiebedarf, Minergie-A für Gebäude, die sich selbst mit  Energie versorgen können, und weitere Spezialstandards, die auf  verschiedene Aspekte des nachhaltigen Bauens abzielen."
    ),
    FAQ(
        "Sind Minergie-Gebäude teurer im Vergleich zu konventionellen Gebäuden?",
        "Obwohl die anfänglichen Investitionskosten für Minergie-Gebäude etwas  höher sein können, sind die mittleren Jahreskosten in der Regel  niedriger. Minergie-Gebäude sind daher langfristig wirtschaftlich und  können ihren Wert besser erhalten."
    ),
    FAQ(
        "Welche Schritte umfasst der Prozess der Minergie-Zertifizierung mit Ecolution?",
        "Der Prozess beginnt mit einer Erstbewertung und Beratung. Nach der  Antragstellung erfolgt eine detaillierte Planung und Umsetzung der  erforderlichen Massnahmen. Ecolution unterstützt Sie während  des gesamten Prozesses und bei der abschließenden Zertifizierung."
    ),
    FAQ(
        "Kann ich Steuervorteile durch Minergie-Sanierung erhalten?",
        "Ja, die Kosten für Minergie-Sanierungen können steuerlich abgesetzt  werden, und in einigen Kantonen gibt es finanzielle Beiträge für solche  Projekte."
    ),
    FAQ(
        "Lohnt es sich, eine besonders hohe Energieeffizienz (z.B MINERGIE P) anzustreben?",
        "Ja, ofmals lohnt es sich, eine besonders hochwertige energetische Sanierung anzustreben, verbunden mit der Vergabe eines entsprechenden Labels (z.B. Minergie-P). Dies wird zunehmend auch durch die Banken/Hypothekargeber gefördert. Ein anerkanntes Label garantiert nebst Energieeffizienz einen hohen Wohnkomfort und eine hochwertige, nachhaltige Bauweise, was sich meist auch auf den Marktwert der Liegenschaft niederschlägt."
    )
)