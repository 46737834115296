package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun DigitaltwinPage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("Digital Twin") {
        org.ecolution.webpage.components.widgets.EcoSpacer()
        org.jetbrains.compose.web.dom.H3(attrs = { id("pionierarbeit-in-der-gebäuderenovation-mit-computer-vision-und-digitalen-zwillingen") }) {
            org.jetbrains.compose.web.dom.Text("Pionierarbeit in der Gebäuderenovation mit Computer Vision und Digitalen Zwillingen")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ecolution arbeitet gemeinsam mit der Fachhochschule Nordwestschweiz (FHNW) und der Universität Zürich (UZH) an einer innovativen Lösung, um den Renovationsprozess von Gebäuden in der Schweiz effizienter zu gestalten. Dabei nutzen wir neueste Technologien wie Computer Vision und Digitale Zwillinge, um eine präzise und automatisierte Analyse des Energiepotenzials von Gebäuden zu ermöglichen.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("der-weg-zu-energieeffizienten-gebäuden") }) {
            org.jetbrains.compose.web.dom.Text("Der Weg zu energieeffizienten Gebäuden")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Schweiz hat sich zum Ziel gesetzt, bis 2050 keine Treibhausgasemissionen mehr aus bestehenden Gebäuden zu erzeugen. Um dieses Ziel zu erreichen, müssen viele ältere Gebäude besser isoliert und mit modernen Heiz-, Lüftungs- und Klimaanlagen ausgestattet werden. Die individuelle Bewertung eines jeden Gebäudes ist jedoch zeitaufwändig und auf manuelle Datenaufnahme angewiesen. Unser gemeinsames Projekt zielt darauf ab, diesen Prozess zu automatisieren und dadurch schneller und genauer zu gestalten.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("die-rolle-von-ecolution-und-den-forschungspartnern") }) {
            org.jetbrains.compose.web.dom.Text("Die Rolle von Ecolution und den Forschungspartnern")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ecolution Engineering bringt seine Expertise in der Erfassung von Gebäudedaten und energetischen Analysen ein, während die Forschungspartner von der FHNW und der UZH fortschrittliche Technologien wie Neuronale Netzwerke und Computer Vision einsetzen. Gemeinsam arbeiten wir daran, aus 2D-Grundrissen oder 3D-Scans ein digitales Abbild des Gebäudes – einen digitalen Zwilling – zu erstellen. Dieser Zwilling dient als Grundlage für genaue Berechnungen des Wärmebedarfs und ermöglicht eine effiziente Planung von Renovationsmassnahmen.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("nutzung-modernster-technologien") }) {
            org.jetbrains.compose.web.dom.Text("Nutzung modernster Technologien")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Durch den Einsatz von optischen Kameras, Laserscanning und Drohnen erfassen wir detaillierte räumliche Daten, die anschliessend mit Hilfe von künstlicher Intelligenz verarbeitet werden. So können Gebäudeeigentümer und Architekten das energetische Potenzial von Immobilien vollautomatisiert bewerten. Dies schafft nicht nur neue Möglichkeiten für Renovationen, sondern auch für innovative Geschäftsmodelle im Immobiliensektor.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-schritt-in-richtung-klimaneutralität") }) {
            org.jetbrains.compose.web.dom.Text("Ein Schritt in Richtung Klimaneutralität")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Da Gebäude für einen Grossteil der CO2-Emissionen in der Schweiz verantwortlich sind, ist die Verbesserung ihrer Energieeffizienz ein wichtiger Baustein auf dem Weg zur Klimaneutralität. Unser Projekt zeigt, wie digitale Zwillinge helfen können, diesen Prozess zu beschleunigen und gleichzeitig neue Geschäftsideen zu fördern.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Mehr Informationen zum Forschungsprojekt finden Sie auf der ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.fhnw.ch/en/about-fhnw/schools/school-of-engineering/institutes/research-projects/building-renovation", "Website der FHNW")
            org.jetbrains.compose.web.dom.Text(".")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(5)
    }
}
