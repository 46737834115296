package org.ecolution.webpage.data

import org.ecolution.webpage.data.Company.Eco.ESG_TITLE_DARK
import org.ecolution.webpage.data.Company.Eco.ESG_TITLE_LIGHT
import org.ecolution.webpage.data.Company.Eco.ESG_URL
import org.ecolution.webpage.data.Company.Eco.SDG_TITLE_DARK
import org.ecolution.webpage.data.Company.Eco.SDG_TITLE_LIGHT
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_NAME
import org.ecolution.webpage.data.Service.Companion.SubNavigation.MINERGIE_NAME

data class Partner(
    val text: String,
    val href: String,
    val imgSrcDarkMode: String,
    val imgSrcLightMode: String,
    val height: Int
) {
    companion object {
        object Partnerships {
            private const val GEAK_IMG_DARK = "partnerships/geak-darkmode.svg"
            private const val MINERGIE_IMG_DARK = "partnerships/minergie-darkmode.svg"
            private const val IFC_IMG_DARK = "partnerships/ifc-darkmode.svg"
            private const val SIA_IMG_DARK = "partnerships/sia-darkmode.svg"

            private const val GEAK_IMG_LIGHT = "partnerships/geak-lightmode.svg"
            private const val MINERGIE_IMG_LIGHT = "partnerships/minergie-lightmode.svg"
            private const val IFC_IMG_LIGHT = "partnerships/ifc-lightmode.svg"
            private const val SIA_IMG_LIGHT = "partnerships/sia-lightmode.svg"

            val partners = listOf(
                Partner(GEAK_NAME, "https://www.geak.ch/", GEAK_IMG_DARK, GEAK_IMG_LIGHT, 3),
                Partner(MINERGIE_NAME, "https://www.minergie.ch/", MINERGIE_IMG_DARK, MINERGIE_IMG_LIGHT, 3),
                Partner("Building Smart", "https://bauen-digital.ch/de/", IFC_IMG_DARK, IFC_IMG_LIGHT, 3),
                Partner("SIA", "https://www.sia.ch/de/", SIA_IMG_DARK, SIA_IMG_LIGHT, 3)
            )
        }

        object ESG {

            const val ESG_SUBTITLE = "Unser Beitrag zu den ESG-Zielen im Rahmen des Pariser Klimaabkommens."

            private const val SDG7_IMG_DARK = "esg/SDG7-darkmode.svg"
            private const val SDG9_IMG_DARK = "esg/SDG9-darkmode.svg"
            private const val SDG11_IMG_DARK = "esg/SDG11-darkmode.svg"
            private const val SDG12_IMG_DARK = "esg/SDG12-darkmode.svg"
            private const val SDG13_IMG_DARK = "esg/SDG13-darkmode.svg"

            private const val SDG7_IMG_LIGHT = "esg/SDG7-lightmode.svg"
            private const val SDG9_IMG_LIGHT = "esg/SDG9-lightmode.svg"
            private const val SDG11_IMG_LIGHT = "esg/SDG11-lightmode.svg"
            private const val SDG12_IMG_LIGHT = "esg/SDG12-lightmode.svg"
            private const val SDG13_IMG_LIGHT = "esg/SDG13-lightmode.svg"

            val esg = listOf(
                Partner("ESG Goal 7", "https://sdgs.un.org/goals/goal7", SDG7_IMG_DARK, SDG7_IMG_LIGHT, 10),
                Partner("ESG Goal 9", "https://sdgs.un.org/goals/goal9", SDG9_IMG_DARK, SDG9_IMG_LIGHT, 10),
                Partner("ESG Goal 11", "https://sdgs.un.org/goals/goal11", SDG11_IMG_DARK, SDG11_IMG_LIGHT, 10),
                Partner("ESG Goal 12", "https://sdgs.un.org/goals/goal12", SDG12_IMG_DARK, SDG12_IMG_LIGHT, 10),
                Partner("ESG Goal 13", "https://sdgs.un.org/goals/goal13", SDG13_IMG_DARK, SDG13_IMG_LIGHT, 10)
            )

            val esgWithTitle = esg + Partner(
                "ESG Sustainability Development Goals", ESG_URL, SDG_TITLE_DARK, SDG_TITLE_LIGHT, 10
            )
        }
    }
}