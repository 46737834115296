package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.ServiceLayout
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Company.Details.CALC_URL
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GA_ANKER
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GA_IMG
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GA_NAME

@Page
@Composable
fun GebaeudeanalysePage() {
    ServiceLayout(
        serviceName = GA_NAME,
        subtitle = SUBTITLE,
        imgsrc = GA_IMG,
        marketing = MARKETING,
        faqs = FAQS,
        faqAnkerId = GA_ANKER,
        buttons = BUTTONS,
        keyFacts = KEYFACTS
    )
}

private const val SUBTITLE = "Der Gebäudeausweis für Industrie | Lager | Spital | Sportbaute | Hallenbad | Versammlungslokal"

private val MARKETING = listOf(
    "Offizieller Energieausweis erhalten",
    "Ihr Begleiter zur Energiestrategie 2050",
    "Garantierte Qualität und Kostentransparenz"
)

private val KEYFACTS = listOf(
    KeyFact(
        imgSrcLightMode = Company.Eco.CASH_LIGHT,
        imgSrcDarkMode = Company.Eco.CASH_DARK,
        title = "Fördergelder beantragen",
        description = "Die Gebäudeanalyse ist eine grundlegende Voraussetzung für die Beantragung von Fördermitteln. Sie deckt Energieeffizienz-Mängel auf, die für den Erhalt finanzieller Unterstützung bei Ihren Sanierungsprojekten erforderlich sind."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.CERTIFICATE_LIGHT,
        imgSrcDarkMode = Company.Eco.CERTIFICATE_DARK,
        title = "Offizieller Energieausweis erhalten",
        description = "Die Gebäudeanalyse zeigt die Energieetikette Ihres Gebäudes auf. Die Etikettierung des Gebäudes erfolgt in den Energieklassen «A» (sehr effizient) bis «G» (wenig effizient). Die Analyse bietet einer potenziellen Käuferschaft Sicherheit und kann somit zu einer Aufwertung der Liegenschaft beitragen."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.OPTIONS_LIGHT,
        imgSrcDarkMode = Company.Eco.OPTIONS_DARK,
        title = "Darstellung von Sanierungsvarianten ",
        description = "Die Gebäudeanalyse zeigt auf, welche Massnahmen zur energetischen Verbesserung der Gebäudehülle oder Gebäudetechnik möglich und sinnvoll sind. Sie dient als Entscheidungsgrundlage und enthält Angaben zu Investitionskosten, Energieeinsparung und Förderbeiträge."
    )
)

private val BUTTONS = listOf(
   // Triple(CALC_URL, "TBD", false),
    //Triple(CALC_URL, "TBD", false),
    Triple(GA_ANKER, "FAQ", true) // if true -> anker
)

private val FAQS = listOf(
    FAQ(
        "Was genau beinhaltet eine Gebäudeanalyse?",
        "Unsere Gebäudeanalyse umfasst eine detaillierte Überprüfung Ihres Gebäudes, einschliesslich der Gebäudehülle, der technischen Systeme und des Energieverbrauchs, um Möglichkeiten zur Steigerung der Energieeffizienz zu identifizieren."
    ),
    FAQ(
        "Wie hilft die Gebäudeanalyse bei der Beantragung von Fördermitteln?",
        "Wir liefern detaillierte Berichte und Dokumentationen, die notwendig sind, um Fördermittel für Energieeffizienzmassnahmen zu beantragen und zu sichern."
    ),
    FAQ(
        "Wie lange dauert eine typische Gebäudeanalyse?",
        "Die Dauer hängt von der Grösse und Komplexität des Gebäudes ab, aber in der Regel dauert eine umfassende Analyse etwa 20 Arbeitstage."
    ),
    FAQ(
        "Was unterscheidet unsere Gebäudeanalyse von anderen Anbietern?",
        "Wir bieten nicht nur eine detaillierte Analyse des aktuellen Zustands, sondern auch massgeschneiderte Lösungsvorschläge und eine umfassende Wirtschaftlichkeitsanalyse."
    ),
    FAQ(
        "Kann jede Art von Gebäude analysiert werden?",
        "Ja, wir sind in der Lage, eine breite Palette von Gebäuden zu analysieren, von Wohnhäusern bis hin zu grossen Industrieanlagen."
    ),
    FAQ(
        "Sind die empfohlenen Massnahmen zur Energieeffizienz wirtschaftlich rentabel?",
        "Ja, unsere Empfehlungen berücksichtigen sowohl die Energieeinsparungen als auch die Investitionskosten, um sicherzustellen, dass die Massnahmen wirtschaftlich sinnvoll sind."
    )
)

