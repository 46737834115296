package org.ecolution.webpage.components.footer

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.ecolution.webpage.components.widgets.*
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Company.Details.ZEFIX_URL
import org.ecolution.webpage.data.Company.Eco.ESG_GOALS_LIGHT
import org.ecolution.webpage.data.Company.Eco.ESG_GOALS_SPECIAL_DARK
import org.ecolution.webpage.data.Company.Eco.ESG_TITLE_DARK
import org.ecolution.webpage.data.Company.Eco.ESG_TITLE_LIGHT
import org.ecolution.webpage.data.Company.Eco.ESG_URL
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun Footer(modifier: Modifier = Modifier) {
    val colorMode by ColorMode.currentState

    Box(
        Modifier.fillMaxWidth().minHeight(200.px).then(modifier),
        contentAlignment = Alignment.Center
    ) {
        Row(
            Modifier.fillMaxWidth().margin(1.em),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                FooterLogo(colorMode)
                Link(
                    "tel:0565522200",
                    "056 552 22 00",
                    Modifier.fontSize(0.75.em).textAlign(TextAlign.Center),
                    SubTitleVariant
                )
                Link(
                    "https://maps.app.goo.gl/jiwNaWH461dpkQJX9",
                    "Sonnenbergstrasse 53, 5408 Ennetbaden",
                    Modifier.fontSize(0.75.em).textAlign(TextAlign.Center),
                    SubTitleVariant
                )

                SpanText(
                    "Copyright © 2024 ${Company.Details.FULL_NAME}. All rights reserved.",
                    Modifier.fontSize(0.5.em).textAlign(TextAlign.Center),
                    SubTitleVariant
                )
            }
        }
    }
}

@Composable
fun FooterLogo(colorMode: ColorMode, height: Int = 30) {
    Anchor(
        href = ZEFIX_URL,
    ) {
        Box(Modifier.margin(4.px).padding(bottom = 0.2.em)) {
            when (colorMode) {
                ColorMode.DARK -> Img(
                    Company.Eco.House_DARK,
                    Company.Details.FULL_NAME,
                    attrs = {
                        style {
                            height(height.px)
                        }
                    }
                )

                ColorMode.LIGHT -> Img(
                    Company.Eco.House_LIGHT,
                    Company.Details.FULL_NAME,
                    attrs = {
                        style {
                            height(height.px)
                        }
                    }
                )
            }
        }
    }
}
