package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.ServiceLayout
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Company.Details.CALC_URL
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_ANKER
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_IMG
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_NAME

@Page
@Composable
fun GeakPage() {
    ServiceLayout(
        serviceName = GEAK_NAME,
        subtitle = SUBTITLE,
        imgsrc = GEAK_IMG,
        marketing = MARKETING,
        faqs = FAQS,
        faqAnkerId = GEAK_ANKER,
        buttons = BUTTONS,
        keyFacts = KEYFACTS
    )
}


private const val SUBTITLE = "Der Gebäudeausweis für Wohnen | Büro | Verkauf | Schule | Restaurant"

private val MARKETING = listOf(
    "Komplette GEAK Plus Beratung von A-Z",
    "Ihr Begleiter zur Energiestrategie 2050",
    "Garantierte Qualität und Kostentransparenz"
)

private val KEYFACTS = listOf(
    KeyFact(
        imgSrcLightMode = Company.Eco.ENERGY_LIGHT,
        imgSrcDarkMode = Company.Eco.ENERGY_DARK,
        title = "Detaillierte Gebäudeanalyse",
        description = "Der GEAK liefert eine umfassende Analyse des energetischen Zustands Ihrer Immobilie, identifiziert Effizienzschwächen der Gebäudehülle und technischen Anlagen und bietet somit eine solide Basis für gezielte Verbesserungsmassnahmen."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.GEAK_LIGHT,
        imgSrcDarkMode = Company.Eco.GEAK_DARK,
        title = "Zertifizierter Energieausweis",
        description = "Als offizielle Energieetikette klassifiziert der GEAK Ihr Gebäude von «A» für sehr effizient bis «G» für wenig effizient. Diese Einstufung dient als verlässliche Information für potenzielle Käufer und kann den Wert Ihrer Liegenschaft erhöhen."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.OPTIONS_GEAKPLUS_LIGHT,
        imgSrcDarkMode = Company.Eco.OPTIONS_GEAKPLUS_DARK,
        title = "Individuelle Sanierungsempfehlungen",
        description = "Der GEAK Plus bietet einen detaillierten Beratungsbericht, der massgeschneiderte Massnahmen zur energetischen Optimierung von Gebäudehülle und -technik aufzeigt. Er bietet eine klare Entscheidungshilfe mit Informationen zu Investitionen, Energieeinsparpotenzialen und verfügbaren Fördermitteln."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.CASH_GEAKPLUS_LIGHT,
        imgSrcDarkMode = Company.Eco.CASH_GEAKPLUS_DARK,
        title = "Zwingende Voraussetzung für Fördermittel",
        description = "Für die Beantragung von Fördermitteln über CHF 10'000 ist der GEAK vom Staat zwingend vorgeschrieben. Dieser dokumentiert den energetischen Zustand Ihrer Immobilie und unterstützt somit effektiv die Beantragung staatlicher Fördergelder für Ihre Sanierungsprojekte."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.INTEREST_GEAKPLUS_LIGHT,
        imgSrcDarkMode = Company.Eco.INTEREST_GEAKPLUS_DARK,
        title = "Optimierte Finanzierungskonditionen",
        description = "Mit einem GEAK Plus können Sie sich bei ihrem Finanzdienstleister für verbesserte Hypothekarkonditionen qualifizieren."
    )
)

private val BUTTONS = listOf(
    Triple(CALC_URL, "Kosten", false),
    Triple(CALC_URL, "Bestellen", false),
    Triple(GEAK_ANKER, "FAQ", true)
)

private val FAQS = listOf(
FAQ(
        question = "Wie kann ich einen GEAK beantragen?",
        answer = "Um einen GEAK zu beantragen, nutzen Sie bitte den Online-GEAK-Rechner auf unserer Webseite. " +
                 "Dort finden Sie eine schrittweise Anleitung, die Sie durch den Antragsprozess führt. "
                //+ "Für weitere Informationen, besuchen Sie bitte den [GEAK-Rechner](Link zur entsprechenden Webseite)."
    ),
    FAQ(
        question = "Wie lange dauert die Erstellung eines GEAK durch Ecolution?",
        answer = "Die Bearbeitungszeit für die Erstellung eines GEAK beträgt nach der Gebäudeerfassung in der Regel bis zu 20 Arbeitstage. " +
                 "Sollten Sie eine schnellere Bearbeitung benötigen, ist dies gegen einen Aufpreis möglich. "
                //+ "Bitte kontaktieren Sie uns hierfür telefonisch unter [Telefonnummer] oder per E-Mail an [E-Mail-Adresse]."
    ),
    FAQ(
        question = "Welche Unterlagen werden für die Erstellung eines GEAK benötigt?",
        answer = "Zur Ausstellung des GEAK-Ausweises benötigen wir folgende Unterlagen: "+
                 "Grundrisse aller beheizten Geschosse und Fassadenpläne (Nord, Ost, Süd, West). " +
                 "Falls verfügbar, sind auch Verbrauchsdaten für Heizung, Warmwasser und Strom der letzten drei Jahre hilfreich."
    ),
    FAQ(
        question = "Für welche Gebäudetypen stellt Ecolution den GEAK aus?",
        answer = "Ecolution stellt den GEAK für alle Gebäudenutzungen aus. Für Industrie, Lager, Sportbauten, Versammlungslokale, Spital und Schwimmbäder wird anstelle des GEAK " +
                 "eine Gebäudeanalyse mit Vorgehensempfehlung erstellt. Dieser ist mit dem GEAK gleichgesetzt."
    ),
    FAQ(
        question = "Ist Ecolution als GEAK-Experte zertifiziert?",
        answer = "Ja, Ecolution ist als GEAK-Experte zertifiziert. Wir verpflichten uns, unsere Zertifizierung regelmässig alle fünf Jahre zu erneuern, " +
                 "um unsere Fachkenntnisse auf dem neuesten Stand zu halten."
    ),
    FAQ(
        question = "Kann ich bei Ecolution eine Aktualisierung meines GEAK beantragen?",
        answer = "Wenn Sie Ihren GEAK bei Ecolution erstellen und anschliessend relevante Sanierungsmassnahmen durchgeführt haben, " +
                 "können Sie eine Aktualisierung Ihres GEAK bei uns beauftragen."
                //+ "Besuchen Sie dazu bitte unsere Webseite unter [Link zur Aktualisierungsseite]."
    )
)