package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.coroutines.delay
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Navigation.HOME_URL
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Img

@Composable
fun HomeLogo(colorMode: ColorMode) {
    Anchor(
        href = HOME_URL,
    ) {
        Box(Modifier.margin(4.px)) {
            when (colorMode) {

                ColorMode.DARK -> Img(
                    Company.Logo.DARK,
                    Company.Details.FULL_NAME,
                    Modifier.height(3.cssRem).display(DisplayStyle.Block).toAttrs()
                )

                ColorMode.LIGHT -> Img(
                    Company.Logo.LIGHT,
                    Company.Details.FULL_NAME,
                    Modifier.height(3.cssRem).display(DisplayStyle.Block).toAttrs()
                )
            }
        }
    }
}

@Composable
fun RotatingLogo(isLoading: Boolean) {
    val colorMode by ColorMode.currentState
    var rotationDegrees by remember { mutableStateOf(0f) }

    // Only run the rotation effect while loading
    LaunchedEffect(isLoading) {
        if (isLoading) {
            while (isLoading) {
                delay(16) // Delay for smooth animation (60 FPS)
                rotationDegrees += 2 // Adjust speed of rotation
                if (rotationDegrees >= 360f) {
                    rotationDegrees = 0f // Reset after full rotation
                }
            }
        }
    }

    if (isLoading) {
        when (colorMode) {
            ColorMode.DARK -> Img(
                src = "logo/ecolution-house-lightmode.svg",
                alt = "",
                Modifier
                    .fillMaxSize()
                    .size(100.px)
                    .display(DisplayStyle.Block)
                    .objectFit(ObjectFit.Cover)
                    .styleModifier {
                        property("transform", "rotate(${rotationDegrees}deg)")
                    }
                    .toAttrs()
            )

            ColorMode.LIGHT -> Img(
                src = "logo/ecolution-house-darkmode.svg",
                alt = "",
                Modifier
                    .fillMaxSize()
                    .size(100.px)
                    .display(DisplayStyle.Block)
                    .objectFit(ObjectFit.Cover)
                    .styleModifier {
                        property("transform", "rotate(${rotationDegrees}deg)")
                    }
                    .toAttrs()
            )
        }
    }
}