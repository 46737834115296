package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.PageLayout
import org.ecolution.webpage.components.sections.ESGSection
import org.ecolution.webpage.components.sections.Ecolution2050
import org.ecolution.webpage.components.sections.EmployeeSection
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.components.widgets.SectionTitle
import org.ecolution.webpage.data.Navigation.ABOUT_NAME


@Page
@Composable
fun AboutPage() {
    PageLayout(ABOUT_NAME) {
        Section {
            SectionTitle(
                title = "Über uns",
                subtitle = "Unser hochmotiviertes Team engagiert sich leidenschaftlich dafür, " +
                        "einen bedeutenden Beitrag zur Erreichung der Energiestrategie-Ziele 2050 " +
                        "in der Schweiz zu leisten."
            )
            EmployeeSection()
            ESGSection()
            Ecolution2050()
        }
    }
}
