package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun OfficePage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("Bürobezug Sonnenbergstrasse") {
        org.ecolution.webpage.components.widgets.EcoSpacer()
        org.jetbrains.compose.web.dom.H3(attrs = { id("neuer-bürostandort-sonnenbergstrasse-53-ennetbaden") }) {
            org.jetbrains.compose.web.dom.Text("Neuer Bürostandort: Sonnenbergstrasse 53, Ennetbaden")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-weiterer-meilenstein") }) {
            org.jetbrains.compose.web.dom.Text("Ein weiterer Meilenstein")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Am ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("01. November 2021")
            }
            org.jetbrains.compose.web.dom.Text(" war es so weit: Ecolution Engineering bezog seinen neuen Bürostandort an der ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Sonnenbergstrasse 53")
            }
            org.jetbrains.compose.web.dom.Text(" in ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Ennetbaden")
            }
            org.jetbrains.compose.web.dom.Text(". Dieser Umzug markierte einen weiteren bedeutenden Schritt in der Entwicklung unseres Unternehmens, das sich seit der Gründung für innovative und nachhaltige Lösungen im Bereich der Energieberatung einsetzt. Die Entscheidung, diesen Standort zu wählen, unterstreicht unsere Mission, nachhaltige Geschäftsprozesse in einem modernen und zukunftsorientierten Umfeld umzusetzen.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-ort-der-zusammenarbeit-und-innovation") }) {
            org.jetbrains.compose.web.dom.Text("Ein Ort der Zusammenarbeit und Innovation")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Unser neues Büro in Ennetbaden bietet nicht nur modern ausgestattete Arbeitsplätze, sondern auch die Möglichkeit, die Synergien innerhalb unseres Teams weiter zu fördern. Hier haben wir den Raum, um gemeinsam an Lösungen für die Herausforderungen der Energiewende zu arbeiten und unser Ziel einer umweltfreundlicheren Zukunft zu verfolgen. Der Standort ermöglicht es uns zudem, noch enger mit unseren Partnern und Kunden zusammenzuarbeiten, um massgeschneiderte und effiziente Energiekonzepte zu entwickeln.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-nachhaltiges-umfeld") }) {
            org.jetbrains.compose.web.dom.Text("Ein nachhaltiges Umfeld")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Wahl von Ennetbaden als neuen Standort war kein Zufall. Umgeben von der malerischen Natur des Aargaus, bietet dieser Ort eine perfekte Balance zwischen der Ruhe der Umgebung und der Nähe zu urbanen Zentren. Dies passt ideal zu unserer Philosophie der Nachhaltigkeit und Effizienz. Die Bürofläche selbst wurde eigenständig designt und eingericht, was es uns ermöglicht, unsere Arbeit in einem wohlfühlend gestalteten Umfeld fortzuführen.")
        }
        org.ecolution.webpage.components.widgets.HqImageGrid()
        org.jetbrains.compose.web.dom.H4(attrs = { id("unsere-vision-in-ennetbaden") }) {
            org.jetbrains.compose.web.dom.Text("Unsere Vision in Ennetbaden")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Mit dem neuen Bürostandort in Ennetbaden wollen wir nicht nur unsere internen Abläufe verbessern, sondern auch ein Zeichen setzen: Nachhaltigkeit beginnt bei uns selbst. Durch die Integration moderner und energieeffizienter Technologien in unserer täglichen Arbeit möchten wir unseren Beitrag zu einer besseren Zukunft leisten. Das neue Büro wird ein Zentrum der Innovation, in dem wir die Ideen entwickeln, die die Welt von morgen prägen werden.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Der Umzug in das Büro ist nur der Anfang – wir sind gespannt auf die kommenden Herausforderungen und freuen uns darauf, diese gemeinsam mit unseren Kunden und Partnern zu meistern.")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(2)
    }
}
