package org.ecolution.webpage.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEmail
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.ecolution.webpage.components.widgets.ButtonShape
import org.ecolution.webpage.components.widgets.CustomButton
import org.ecolution.webpage.components.widgets.EcoSpacer
import org.ecolution.webpage.data.Employee
import org.ecolution.webpage.data.Employee.Companion.Employee.employees
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun EmployeeSection() {
    val breakpoint = rememberBreakpoint()

    if (breakpoint < Breakpoint.MD) {
        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center
        ) {
            SimpleGrid(
                numColumns = numColumns(1)
            ) {
                employees.forEach { employee -> EmployeeCardSmallScreen(employee) }
            }
        }
    } else {
        Box(
            modifier = Modifier.fillMaxSize().padding(leftRight = 2.cssRem),
            contentAlignment = Alignment.Center
        ) {
            SimpleGrid(
                numColumns = numColumns(1, xl = 2)
            ) {
                employees.forEach { employee -> EmployeeCardLargeScreen(employee) }
            }
        }
    }
}

@Composable
private fun EmployeeCardLargeScreen(employee: Employee) {
    val color = ColorMode.current.toSitePalette().secondaryBackground

    Box(
        modifier = Modifier
            .backgroundColor(color)
            .borderRadius(5.px)
            .padding(2.cssRem)
            .margin(2.cssRem)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            EmployeeImage(employee, Modifier.size(200.px))
            Box(
                contentAlignment = Alignment.Center
            ) {
                Column(Modifier.padding(left = 2.cssRem)) {
                    createQuote(employee.quote)
                    createEmployeeContent(employee)
                }
            }
        }
    }
}

@Composable
private fun EmployeeCardSmallScreen(employee: Employee) {
    val color = ColorMode.current.toSitePalette().secondaryBackground

    Box(
        modifier = Modifier
            .backgroundColor(color)
            .borderRadius(5.px)
            .padding(2.cssRem)
            .margin(20.px)
    ) {
        Column {
            EmployeeImage(employee, Modifier.size(300.px))
            createEmployeeContent(employee)
        }
    }
}

@Composable
private fun createQuote(quote: String) {
    H5 {
        SpanText(
            "“ $quote ”"
        )
    }
}

@Composable
private fun createEmployeeContent(employee: Employee) {
    Box(
        Modifier.fillMaxWidth().margin(top = 1.cssRem)
    ) {
        Box(contentAlignment = Alignment.CenterStart) {
            Column {
                if (employee.email == null) {
                    Box(modifier = Modifier.margin(bottom = 1.5.em))
                }
                createTitle(employee.name)
                createProfession(employee.profession)
            }
        }
        Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.CenterEnd) {
            employee.email?.let { createEmailButton(it) }
        }
    }
}

@Composable
private fun createProfession(profession: String) {
    H6(Modifier.margin(0.px).toAttrs()) {
        SpanText(
            profession,
            Modifier.fontWeight(FontWeight.Normal)
        )
    }
}

@Composable
private fun createTitle(title: String) {
    H5(Modifier.margin(0.px).toAttrs()) {
        SpanText(
            title,
            Modifier.fontWeight(FontWeight.Bold)
        )
    }
}

@Composable
private fun createEmailButton(email: String) {
    val iconColor = ColorMode.current.toSitePalette().text

    CustomButton(
        onClick = { window.location.href = email },
        shape = ButtonShape.CIRCLE
    ) {
        MdiEmail(Modifier.color(iconColor))
    }
}

@Composable
private fun EmployeeImage(employee: Employee, modifier: Modifier) {
    Img(
        src = employee.imagSrc,
        alt = employee.name,

        Modifier
            .fillMaxSize()
            .display(DisplayStyle.Block)
            .objectFit(ObjectFit.Contain)
            .then(modifier).toAttrs()
    )
}
