package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.PageLayout
import org.ecolution.webpage.components.sections.*
import org.ecolution.webpage.data.Company.Details.HOME_NAME

@Page
@Composable
fun HomePage() {
    PageLayout(HOME_NAME) {
        WelcomeSection()
        ReferenceSectionTitle()
        ReferenceSection()
        PartnershipSection()
    }
}
