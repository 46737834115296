package org.ecolution.webpage.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.objectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.datetime.LocalDate
import org.ecolution.webpage.components.widgets.*
import org.ecolution.webpage.data.Blog
import org.ecolution.webpage.data.Blog.Companion.blogs
import org.ecolution.webpage.style.rememberHoverFilter
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

private val BLOGCARD_BORDER_RADIUS = 5.px
private val BLOGCARD_PADDING_BOTTOM = 1.cssRem
private val BLOGCARD_MARGIN = 2.cssRem
private val DISPLAY_REFERENCES_LARGE_SCREEN = 9
private val DISPLAY_REFERENCES_SMALL_SCREEN = 6

@Composable
fun BlogSectionTitle() {
    SectionTitle(
        title = "Unsere Neuigkeiten",
        subtitle = "Individuelle Beratungen für nachhaltige Gebäude"
    )
}

@Composable
fun BlogSection() {
    val breakpoint = rememberBreakpoint()
    val (numItems, setNumItems) = remember { mutableStateOf(0) }

    updateNumItems(breakpoint, setNumItems)

    createSimpleGrid(numItems)

    if (numItems < blogs.size) {
        createLoadMoreButton(numItems, setNumItems)
    }
}

@Composable
private fun BlogCard(blog: Blog, openInNewTab: Boolean = true) { //todo or remove?
    val ctx = rememberPageContext()
    val colorMode = ColorMode.current
    val (filter, hoverModifier) = rememberHoverFilter()

    Box(
        modifier = Modifier
            .backgroundColor(colorMode.toSitePalette().secondaryBackground)
            .borderRadius(BLOGCARD_BORDER_RADIUS)
            .padding(bottom = BLOGCARD_PADDING_BOTTOM)
            .margin(BLOGCARD_MARGIN)
            .cursor(Cursor.Pointer)
            .then(hoverModifier)
            .onClick {
                ctx.router.tryRoutingTo(blog.contentPath)
            }
    ) {
        Column(modifier = Modifier.margin(10.px)) {
            BlogImage(blog, filter.value)
            createTitle(blog)
            createBadges(blog)
            createDescription(blog)
            Column {
                createAuthorAndDate(blog)
                Spacer()
                createReadTime(blog)
            }
        }
    }
}

@Composable
private fun createSimpleGrid(numItems: Int) {
    SimpleGrid(
        numColumns = numColumns(1, sm = 1, md = 2, lg = 2, xl = 3),
        modifier = Modifier.fillMaxWidth()
    ) {
        blogs.take(numItems).forEach { feature -> BlogCard(feature, true) }
    }
}

@Composable
private fun createBadges(blog: Blog) {
    SimpleGrid(numColumns(3)) {
        blog.tags.forEach { tag ->
            BlogBadge(tag)
        }
    }
}

@Composable
private fun createDescription(blog: Blog) {
    Row {
        SpanText(blog.description, Modifier.padding(topBottom = 5.px, leftRight = 10.px))
    }
}

@Composable
private fun createTitle(blog: Blog) {
    var colorMode by ColorMode.currentState
    val iconColor = ColorMode.current.toSitePalette().oppositeText

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.margin(left = 0.5.cssRem, right = 0.5.cssRem, top = 0.75.cssRem, bottom = 0.75.cssRem)
    ) {
        if (colorMode.isLight) blog.icon(Modifier.color(iconColor)) else blog.icon(Modifier.color(iconColor))
        Spacer()
        SpanText(
            blog.title,
            Modifier.fontSize(20.px).fontWeight(FontWeight.Bold).padding(left = 0.5.cssRem)
        )
    }
}

@Composable
private fun BlogImage(blog: Blog, filter: String) {
    Img(
        src = blog.imgSrc,
        alt = blog.imgSrc,
        Modifier
            .fillMaxSize()
            .aspectRatio(1.0f, 0.7f)
            .display(DisplayStyle.Block)
            .objectFit(ObjectFit.Cover)
            .borderRadius(topRight = BLOGCARD_BORDER_RADIUS, topLeft = BLOGCARD_BORDER_RADIUS)
            .styleModifier {
                property("filter", filter)
            }
            .toAttrs()
    )
}

@Composable
private fun createAuthorAndDate(blog: Blog) {
    val color = ColorMode.current.toSitePalette().primaryButton
    val colorMode by ColorMode.currentState

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.px, bottom = 5.px)
    ) {
        // Verwende `attrs` für die Stile
        Box(
            modifier = Modifier
                .size(32.px)
                .backgroundColor(color)
                .borderRadius(50.px)
                .display(DisplayStyle.Flex)
                .justifyContent(JustifyContent.Center)
                .alignItems(AlignItems.Center)
        ) {
            when (colorMode) {
                ColorMode.DARK -> Img(
                    src = blog.publishSrcLight,
                    alt = blog.author,
                    attrs = {
                        style {
                            width(25.px)
                            height(25.px)
                            objectFit(ObjectFit.Cover)
                            maxWidth(100.percent)
                            maxHeight(100.percent)
                        }
                    }
                )

                ColorMode.LIGHT -> Img(
                    src = blog.publishSrcDark,
                    alt = blog.author,
                    attrs = {
                        style {
                            width(25.px)
                            height(25.px)
                            objectFit(ObjectFit.Cover)
                            maxWidth(100.percent)
                            maxHeight(100.percent)
                        }
                    }
                )
            }
        }

        Div(attrs = {
            style {
                width(8.px)
            }
        }
        )

        Column {
            SpanText(blog.author, Modifier.fontWeight(FontWeight.Bold).fontSize(14.px))
            SpanText(formatSwissDateLong(blog.publishedDate), Modifier.fontWeight(FontWeight.Light).fontSize(12.px))
        }
    }
}

@Composable
private fun createReadTime(blog: Blog) {
    Row(
        horizontalArrangement = Arrangement.SpaceBetween,
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 5.px)
    ) {
        SpanText("${blog.readTime} Min Lesezeit", Modifier.fontWeight(FontWeight.Light).fontSize(12.px))
    }
}


fun formatSwissDateLong(date: LocalDate): String {
    val day = date.dayOfMonth
    val month = when (date.monthNumber) {
        1 -> "Januar"
        2 -> "Februar"
        3 -> "März"
        4 -> "April"
        5 -> "Mai"
        6 -> "Juni"
        7 -> "Juli"
        8 -> "August"
        9 -> "September"
        10 -> "Oktober"
        11 -> "November"
        12 -> "Dezember"
        else -> throw IllegalArgumentException("Ungültiger Monat")
    }
    val year = date.year
    return "$day. $month $year"
}