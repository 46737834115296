package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.objectFit
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import org.ecolution.webpage.data.ImageItem
import org.ecolution.webpage.style.rememberHoverFilter
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img

@Composable
fun FoundationMap() {
    ImgWithCaption(ImageItem("blogs/foundation.webp", "Route des Road Trips | Quelle: Ecolution"))
}

@Composable
fun HqImageGrid() {
    val list = listOf(
        ImageItem("/blogs/hq1.webp", "Worte sind gut, Taten sind besser – hier werden sie Realität."),
        ImageItem("/blogs/hq2.webp", "Willkommen an einem Ort, wo Ideen ihren Anfang nehmen."),
        ImageItem("/blogs/hq3.webp", "Zeit zum Auftanken – hier entstehen neue Energien."),
        ImageItem("/blogs/hq4.webp", "Hier wird die Zukunft grenzenlos gestaltet.")
    )

    ImageGrid(list)
}

@Composable
private fun ImageGrid(list: List<ImageItem>) {
    SimpleGrid(
        numColumns = numColumns(1, sm = 1, md = 2, lg = 2, xl = 2),
        modifier = Modifier.fillMaxWidth()
    ) {
        list.forEach { entry -> ImgWithCaption(entry) }
    }
}

@Composable
private fun ImgWithCaption(imageItem: ImageItem) {
    val (filter, hoverModifier) = rememberHoverFilter()

    Column(Modifier.padding(16.px).then(hoverModifier)) {
        CustomImage(imageItem, filter.value)
        SpanText(
            text = imageItem.captionText,
            Modifier.padding(top = 8.px).fontSize(10.px),
            variant = SubTitleVariant
        )
    }
}

@Composable
private fun CustomImage(imageItem: ImageItem, filter: String) {
    Img(
        src = imageItem.sourcePath,
        alt = imageItem.captionText,
        Modifier
            .fillMaxSize()
            .aspectRatio(1.0f, 0.7f)
            .display(DisplayStyle.Block)
            .objectFit(ObjectFit.Cover)
            .styleModifier {
                property("filter", filter)
            }
            .toAttrs()
    )
}

