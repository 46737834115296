package org.ecolution.webpage.data

import org.ecolution.webpage.data.Service.Companion.SubNavigation.EN_NAME
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GA_NAME
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_NAME
import org.ecolution.webpage.data.Service.Companion.SubNavigation.GEAK_PLUS_NAME

data class Reference(

    var title: String,
    val canton: Canton,
    val usage: List<String>,
    val certificates: List<String>,
    val ebf: Int,
    val description: String,
    val imgSrc: String,
) {
    companion object{
        object References {

            const val WOHNEN = "Wohnen"
            const val INDUSTRIE = "Industrie"
            const val LAGER = "Lager"
            const val VERKAUF = "Verkauf"
            const val RESTAURANT = "Gastro"
            const val BÜRO = "Büro"
            const val SCHWIMMBAD = "Schwimmbad"
            const val SPITAL = "Spital"
            const val KINO = "Kino"
            const val WERKSTATT = "Werkstatt"
            const val SCHULE = "Schule"
            const val STWEG = "Stockwerkeigentum"

            const val R1_IMG = "references/energienachweis-efh-ag-208.webp"
            const val R2_IMG = "references/energienachweis-mfh-ag-1238.webp"
            const val R3_IMG = "references/geakplus-efh-so-280.webp"
            const val R4_IMG = "references/geakplus-verkauf-ag-3097.webp"
            const val R5_IMG = "references/gebaeudeanalyse-industrie-zh-2523.webp"
            const val R6_IMG = "references/gebaeudeanalyse-produktion-ag-5358.webp"
            const val R7_IMG = "references/gebaeudeanalyse-schwimmbad-ag-4903.webp"
            const val R8_IMG = "references/gebaeudeanalyse-spital-ag-13254.webp"
            const val R9_IMG = "references/gebeaudeanalyse-kino-ag-1026.webp"
            const val R10_IMG = "references/geakplus-mfh-ag-5950.webp"
            const val R11_IMG = "references/geakplus-mfh-bl-4194.webp"
            const val R12_IMG = "references/ga-lager-verwaltung-ag-6016.webp"
            const val R13_IMG = "references/geakplus-verwaltung-verkauf-ag-5212.webp"
            const val R14_IMG = "references/geakplus-verwaltung-verkauf-ag-4149.webp"
            const val R15_IMG = "references/geak-verwaltung-zg-3584.webp"
            const val R16_IMG = "references/geakplus-mfh-verwaltung-verkauf-ag-3771.webp"
            const val R17_IMG = "references/geakplus-mfh-zh-1369.webp"
            const val R18_IMG = "references/energienachweis-mfh-tg-2156.webp"
            const val R19_IMG = "references/geakplus-mfh-be-854.webp"
            const val R20_IMG = "references/geakplus-mfh-zh-1350.webp"
            const val R21_IMG = "references/geakplus-efh-ag-874.webp"
            const val R22_IMG = "references/gebaeudeanalyse-verwaltung-verkauf-werkstatt-ag-11369.webp"
            const val R23_IMG = "references/gebaeudeanalyse-verwaltung-industrie-lager-verkauf-ag-6320.webp"
            const val R24_IMG = "references/gebaeudeanalyse-energienachweis-industrie-lager-verwaltung-18632.webp"
            const val R25_IMG = "references/geakplus-ehf-ag-319.webp"
            const val R26_IMG = "references/geakplus-mfh-ag-561.webp"
            const val R27_IMG = "references/geakplus-mfh-bl-3932.webp"
            const val R28_IMG = "references/geakplus-mfh-schule-restaurant-ag-5209.webp"
            const val R29_IMG = "references/geakplus-schule-ag-5673.webp"
            const val R30_IMG = "references/geakplus-schule-verwaltung-zh-7351.webp"
            const val R31_IMG = "references/geakplus-stweg-ag-1509.webp"
            const val R32_IMG = "references/geakplus-verkauf-zh-910.webp"
            const val R33_IMG = "references/geakplus-verwaltung-ag-4068.webp"
            const val R34_IMG = "references/gebaeudeanalyse-industrie-ag-2797.webp"
            const val R35_IMG = "references/gebaeudeanalyse-industrie-verwaltung-ag-12893.webp"
            const val R36_IMG = "references/gebaeudeanalyse-industrie-ag-6177.webp"

            val buildingRef = listOf(
                Reference("", Canton.AG, listOf(SCHULE, WOHNEN, RESTAURANT), listOf(GEAK_PLUS_NAME), 5209, "TBD", R28_IMG),
                Reference("", Canton.AG, listOf(INDUSTRIE, BÜRO), listOf(GA_NAME), 12893, "TBD", R35_IMG),
                Reference("", Canton.AG, listOf(STWEG), listOf(GEAK_PLUS_NAME), 1509, "TBD", R31_IMG),
                Reference("", Canton.AG, listOf(INDUSTRIE), listOf(GA_NAME), 6177, "TBD", R36_IMG),

                Reference("", Canton.AG, listOf(INDUSTRIE), listOf(GA_NAME), 2797, "TBD", R34_IMG),
                Reference("", Canton.AG, listOf(BÜRO), listOf(GEAK_PLUS_NAME), 4068, "TBD", R33_IMG),
                Reference("", Canton.ZH, listOf(VERKAUF), listOf(GEAK_PLUS_NAME), 910, "TBD", R32_IMG),
                Reference("", Canton.ZH, listOf(SCHULE, BÜRO), listOf(GEAK_PLUS_NAME), 7351, "TBD", R30_IMG),
                Reference("", Canton.AG, listOf(SCHULE), listOf(GEAK_PLUS_NAME), 5673, "TBD", R29_IMG),
                Reference("", Canton.BL, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 3932, "TBD", R27_IMG),
                Reference("", Canton.AG, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 561, "TBD", R26_IMG),
                Reference("", Canton.AG, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 319, "TBD", R25_IMG),
                Reference("", Canton.AG, listOf(VERKAUF, BÜRO, WOHNEN), listOf(GEAK_PLUS_NAME), 3097,"TBD", R4_IMG),
                Reference("", Canton.ZH, listOf(WOHNEN), listOf(GEAK_PLUS_NAME, EN_NAME), 1350,"TBD", R20_IMG),
                Reference("", Canton.AG, listOf(SPITAL), listOf(GA_NAME), 13254,"TBD", R8_IMG),
                Reference("", Canton.AG, listOf(INDUSTRIE, LAGER, BÜRO), listOf(GEAK_NAME, EN_NAME), 18632,"TBD", R24_IMG),
                Reference("", Canton.AG, listOf(BÜRO, VERKAUF), listOf(GEAK_PLUS_NAME), 4149,"TBD", R14_IMG),
                Reference("", Canton.SO, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 280,"TBD", R3_IMG),
                Reference("", Canton.ZG, listOf(BÜRO), listOf(GEAK_NAME), 3584,"TBD", R15_IMG),
                Reference("", Canton.TG, listOf(WOHNEN), listOf(EN_NAME), 2156,"TBD", R18_IMG),
                Reference("", Canton.AG, listOf(SCHWIMMBAD), listOf(GA_NAME), 4903,"TBD", R7_IMG),

                Reference("", Canton.AG, listOf(WOHNEN), listOf(GEAK_PLUS_NAME, EN_NAME), 208,"TBD", R1_IMG),
                Reference("", Canton.AG, listOf(WOHNEN), listOf(EN_NAME), 1238,"TBD", R2_IMG),
                Reference("", Canton.ZH, listOf(INDUSTRIE, LAGER), listOf(GA_NAME), 2523,"TBD", R5_IMG),
                Reference("", Canton.AG, listOf(INDUSTRIE, LAGER), listOf(GA_NAME), 5358,"TBD", R6_IMG),
                Reference("", Canton.AG, listOf(KINO), listOf(GA_NAME), 1026,"TBD", R9_IMG),
                Reference("", Canton.AG, listOf(WOHNEN), listOf(GEAK_PLUS_NAME, EN_NAME), 5950,"TBD", R10_IMG),
                Reference("", Canton.BL, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 4194,"TBD", R11_IMG),
                Reference("", Canton.AG, listOf(LAGER, BÜRO), listOf(GA_NAME), 6016,"TBD", R12_IMG),
                Reference("", Canton.AG, listOf(BÜRO, VERKAUF), listOf(GEAK_PLUS_NAME), 5212,"TBD", R13_IMG),
                Reference("", Canton.AG, listOf(BÜRO, VERKAUF, WOHNEN), listOf(GEAK_PLUS_NAME), 3771,"TBD", R16_IMG),
                Reference("", Canton.ZH, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 1369,"TBD", R17_IMG),
                Reference("", Canton.BE, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 854,"TBD", R19_IMG),
                Reference("", Canton.AG, listOf(WOHNEN), listOf(GEAK_PLUS_NAME), 874,"TBD", R21_IMG),
                Reference("", Canton.AG, listOf(BÜRO, VERKAUF, WERKSTATT), listOf(GA_NAME), 11369,"TBD", R22_IMG),
                Reference("", Canton.AG, listOf(VERKAUF, BÜRO, LAGER), listOf(GA_NAME), 6320,"TBD", R23_IMG)
            ).sortByCertificates().formatUsages()

        }

        private fun List<Reference>.sortByCertificates(): List<Reference> {
            val firstNine = this.take(9)
            val rest = this.drop(9).sortedWith(compareBy({ it.certificates.contains(GA_NAME) },
                { it.certificates.contains(EN_NAME) },
                { it.certificates.sorted().joinToString() })).reversed()
            return firstNine + rest
        }

        fun List<Reference>.formatUsages(): List<Reference> {
            this.forEach { reference ->
                val formattedUsages = reference.usage.joinToString(separator = "  |  ")
                reference.title = formattedUsages
            }
            return this
        }
    }
}

