package org.ecolution.webpage.data

object Company {

    object Details {
        const val NAME = "Ecolution"
        const val FULL_NAME = "Ecolution Engineering"
        const val HOME_NAME = "Energieberatung"
        const val ADDRESS = "Ecolution Engineering GmbH\nSonnenbergstrasse 53\n5408 Ennetbaden"
        const val PHONE = "+41 56 552 22 00"
        const val EMAIL = "mailto:info@ecolution.swiss"
        const val CALC_URL = "https://geak-rechner.ecolution.swiss"
        const val ZEFIX_URL = "https://ag.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-498.998.299"

        const val WELCOME_TEXT = "wo wir mit Leidenschaft und Expertise an einer nachhaltigen Zukunft arbeiten.\n" +
                "\n" +
                "Seit unserer Gründung im Jahr 2019 sind wir bestrebt, die Art und Weise, wie wir über Energieeffizienz und umweltfreundliches Bauen denken, zu verändern. Unser Ziel ist es, durch sorgfältige Analysen und massgeschneiderte Beratung einen echten Unterschied in der Welt der Energie zu machen.\n" +
                "\n" +
                "In unserem Team vereinen wir technisches Know-how mit einem tiefen Verständnis für die Bedürfnisse unserer Umwelt und unserer Kunden. Gemeinsam mit Ihnen möchten wir die Schritte in eine umweltbewusste und effiziente Zukunft gestalten.\n" +
                "\n" +
                "Wir freuen uns darauf, Sie auf diesem spannenden Weg zu begleiten."

    }

    object Logo {
        const val DARK = "logo/logo-darkmode.png"
        const val LIGHT = "logo/logo-lightmode.png"
    }

    object Eco {
        const val Desktop = "logo/ecolution-desktop.ico"
        const val ECOLUTION_2050 = "logo/2050.svg"

        const val House_DARK = "logo/ecolution-house-darkmode.svg"
        const val House_LIGHT = "logo/ecolution-house-lightmode.svg"

        const val ESG_GOALS_DARK = "esg/title-darkmode.svg"
        const val ESG_GOALS_DARKER_DARK = "esg/title-darker-darkmode.svg"
        const val ESG_GOALS_SPECIAL_DARK = "esg/title-special-darkmode.svg"
        const val ESG_GOALS_LIGHT = "esg/title-lightmode.svg"

        const val ESG_TITLE_LIGHT = "esg/esg-title-light.svg"
        const val ESG_TITLE_DARK = "esg/esg-title-dark.svg"

        const val SDG_TITLE_LIGHT = "esg/SDG-title-lightmode.svg"
        const val SDG_TITLE_DARK = "esg/SDG-title-darkmode.svg"

        const val ESG_URL = "https://sdgs.un.org/goals"

        const val INTEREST_LIGHT = "icons/interest-lightmode.svg"
        const val INTEREST_DARK = "icons/interest-darkmode.svg"

        const val OPTIONS_LIGHT = "icons/options-lightmode.svg"
        const val OPTIONS_DARK = "icons/options-darkmode.svg"

        const val OPTIONS_GEAKPLUS_DARK = "icons/options-geakplus-darkmode.svg"
        const val OPTIONS_GEAKPLUS_LIGHT = "icons/options-geakplus-lightmode.svg"

        const val CASH_DARK = "icons/cash-darkmode.svg"
        const val CASH_LIGHT = "icons/cash-lightmode.svg"

        const val CASH_GEAKPLUS_DARK = "icons/cash-geakplus-darkmode.svg"
        const val CASH_GEAKPLUS_LIGHT = "icons/cash-geakplus-lightmode.svg"

        const val CASH_ADDON_DARK = "icons/cash-addon-darkmode.svg"
        const val CASH_ADDON_LIGHT = "icons/cash-addon-lightmode.svg"

        const val CERTIFICATE_DARK = "icons/certificate-darkmode.svg"
        const val CERTIFICATE_LIGHT = "icons/certificate-lightmode.svg"

        const val ENERGY_DARK = "icons/energy-darkmode.png"
        const val ENERGY_LIGHT = "icons/energy-lightmode.svg"

        const val EXPERT_DARK = "icons/expert-darkmode.svg"
        const val EXPERT_LIGHT = "icons/expert-lightmode.svg"

        const val GEAK_DARK = "icons/geak-darkmode.svg"
        const val GEAK_LIGHT = "icons/geak-lightmode.svg"

        const val INCREASE_DARK = "icons/increase-darkmode.svg"
        const val INCREASE_LIGHT = "icons/increase-lightmode.svg"

        const val INTEREST_GEAKPLUS_DARK = "icons/interest-geakplus-darkmode.svg"
        const val INTEREST_GEAKPLUS_LIGHT = "icons/interest-geakplus-lightmode.svg"

        const val MAP_DARK = "icons/map-darkmode.svg"
        const val MAP_LIGHT = "icons/map-lightmode.svg"

        const val TALK_DARK = "icons/talk-darkmode.svg"
        const val TALK_LIGHT = "icons/talk-lightmode.svg"

        const val WORLD_DARK = "icons/world-darkmode.svg"
        const val WORLD_LIGHT = "icons/world-lightmode.svg"

        const val TAX_DARK = "icons/tax-darkmode.svg"
        const val TAX_LIGHT = "icons/tax-lightmode.svg"
    }

}