package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Shape
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.ecolution.webpage.data.Partner
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img

@Composable
fun PartnerGrid(partner: Partner, setScale: Boolean) {
    val colorMode by ColorMode.currentState
    var hoverWidth by remember { mutableStateOf(partner.height.cssRem) }

    Column(
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = getGridItemModifier()
    ) {
        Anchor(partner.href, content = {
            Box(
                modifier = Modifier
                    .height(partner.height.cssRem),
                contentAlignment = Alignment.Center
            ) {
                Img(
                    src = getImageSource(colorMode, partner),
                    alt = partner.text,
                    Modifier
                        .height(hoverWidth)
                        .display(DisplayStyle.Block)
                        .onMouseEnter { hoverWidth *= 1.05 }
                        .onMouseLeave { hoverWidth /= 1.05 }
                        .align(Alignment.Center)
                        .scale(if(setScale) 0.6 else 1)
                        .toAttrs()
                )
            }
        })
    }
}

private fun getGridItemModifier() = Modifier
    .padding(topBottom = 0.px, leftRight = 3.cssRem)
    .margin(10.px)
    .borderRadius(12.px)
    .padding(0.5.em)

private fun getImageSource(colorMode: ColorMode, partner: Partner) = when (colorMode) {
    ColorMode.DARK -> partner.imgSrcDarkMode
    ColorMode.LIGHT -> partner.imgSrcLightMode
}