package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontStyle
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.text.SpanText
import org.ecolution.webpage.components.sections.formatSwissDateLong
import org.ecolution.webpage.data.Blog
import org.ecolution.webpage.data.Blog.Companion.blogs
import org.jetbrains.compose.web.css.px

@Composable
fun BlogIntro(blogId: Int) {
    val blog = blogs.find { it.id.toIntOrNull() == blogId } ?: error("Blog with id $blogId not found")

    HorizontalDivider()
    Column(Modifier.fontStyle(FontStyle.Italic).padding(topBottom = 15.px)) {
        SpanText("Autor: ${blog.author}")
        SpanText("Veröffentlichungsdatum: ${formatSwissDateLong(blog.publishedDate)}")
        SpanText("Lesezeit: ${blog.readTime} Minuten")
    }
}