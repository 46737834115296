package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.defer.deferRender
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.ecolution.webpage.components.footer.Footer
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*
import org.w3c.dom.HTMLBodyElement

@Composable
fun SideMenu(menuState: SideMenuState, closeMenu: () -> Unit, onAnimationEnd: () -> Unit) {
    val colorMode by ColorMode.currentState

    // Manage body overflow to prevent background scrolling
    if (menuState != SideMenuState.CLOSED) {
        DisposableEffect(Unit) {
            val body = document.body as HTMLBodyElement?
            val style = body?.style

            // Store original overflowX and overflowY values
            val originalOverflowX = style?.getPropertyValue("overflow-x")
            val originalOverflowY = style?.getPropertyValue("overflow-y")

            // Set overflow-x and overflow-y to "hidden"
            style?.setProperty("overflow-x", "hidden")
            style?.setProperty("overflow-y", "hidden")

            onDispose {
                // Restore original overflow-x value
                if (originalOverflowX != null && originalOverflowX.isNotEmpty()) {
                    style?.setProperty("overflow-x", originalOverflowX)
                } else {
                    style?.removeProperty("overflow-x")
                }

                // Restore original overflow-y value
                if (originalOverflowY != null && originalOverflowY.isNotEmpty()) {
                    style?.setProperty("overflow-y", originalOverflowY)
                } else {
                    style?.removeProperty("overflow-y")
                }
            }
        }
    }

    deferRender {
        Overlay(
            Modifier
                .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
                .onClick { closeMenu() }

        ) {
            key(menuState) { // Force recompute animation parameters when close button is clicked
                Box(
                    Modifier
                        .fillMaxSize()
                        .align(Alignment.Center)
                        // Close button will appear roughly over the hamburger button, so the user can close
                        // things without moving their finger / cursor much.
                        .padding(top = 1.cssRem, left = 2.cssRem, right = 0.cssRem, bottom = 2.cssRem)
                        .gap(1.5.cssRem)
                        .backgroundColor(ColorMode.current.toSitePalette().secondaryBackground)
                        .animation(
                            SideMenuSlideInAnim.toAnimation(
                                duration = 300.ms,
                                timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                                direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                                fillMode = AnimationFillMode.Forwards
                            )
                        )
                        .borderRadius(topLeft = 0.5.cssRem)
                        .onClick { it.stopPropagation() }
                        .onAnimationEnd { onAnimationEnd() },
                    contentAlignment = Alignment.TopEnd
                ) {
                    Column(
                        Modifier
                            .fillMaxHeight()
                            .margin(right = 2.cssRem, top = 0.5.cssRem),
                        horizontalAlignment = Alignment.End
                    ) {
                        Box(
                            Modifier.height(3.cssRem),
                            contentAlignment = Alignment.TopEnd
                        ) {
                            CloseButton(onClick = { closeMenu() })
                        }
                        Box(Modifier.fillMaxHeight()) {
                            Column(
                                Modifier
                                    .padding(right = 0.75.cssRem)
                                    .gap(1.5.cssRem)
                                    .fontSize(1.4.cssRem),
                                horizontalAlignment = Alignment.End
                            ) {
                                SideMenuItems(closeMenu = closeMenu)
                                Row(Modifier.gap(1.cssRem)) {
                                    CalculatorButton()
                                    EmailButton()
                                    PhoneButton()
                                    ColorModeButton()
                                }
                            }
                        }
                    }
                    Box(Modifier.align(Alignment.BottomCenter)) {
                        Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
                            Footer()
                        }
                    }
                }
            }
        }
    }
}
