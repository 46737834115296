package org.ecolution.webpage.components.sections.service

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.components.widgets.AccordionItem
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

@Composable
fun FaqSection(
    items: List<FAQ>,
    title: String,
    faqAnkerId: String
) {
    var expandedItem by remember { mutableStateOf<String?>(null) }
    var searchQuery by remember { mutableStateOf("") }
    val breakpoint = rememberBreakpoint()
    val colorH2 = ColorMode.current.toSitePalette().faqTitle

    val gridWidth = when {
        breakpoint >= Breakpoint.MD -> Modifier.width(800.px)
        breakpoint >= Breakpoint.SM -> Modifier.width(500.px)
        else -> Modifier.fillMaxWidth()
    }

    Section(
        modifier = FAQHalfPageContentStyle.toModifier().id(faqAnkerId),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column {
            Row(
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                H1(
                    attrs = Modifier
                        .color(colorH2)
                        .fontWeight(FontWeight.Lighter)
                        .toAttrs ()
                ) {
                    Text("$title FAQ")
                }
                if(breakpoint >= Breakpoint.MD) {
                    SearchField(searchQuery) { newQuery -> searchQuery = newQuery }
                }
            }
            HorizontalDivider(modifier = Modifier.fillMaxWidth())
            SimpleGrid(
                numColumns = numColumns(1),
                modifier = gridWidth
            ) {
                items.filter {
                    it.question.contains(searchQuery, ignoreCase = true) || it.answer.contains(
                        searchQuery,
                        ignoreCase = true
                    )
                }.forEach { item ->
                    AccordionItem(item = item, expandedItem = expandedItem) { expandedItem = it }
                }
            }
        }
    }
}

@Composable
private fun SearchField(searchQuery: String, onSearchQueryChange: (String) -> Unit) {
    Input(
        type = InputType.Text,
        attrs = {
            value(searchQuery)
            onInput { event ->
                onSearchQueryChange(event.value)
            }
            placeholder(" Suchen...")
            style {
                property(
                    "outline",
                    "none"
                )
                property(
                    "box-shadow",
                    "0 0 5px white"
                )
            }
        }
    )
}

val FAQHalfPageContentStyle by ComponentStyle {
    base { Modifier.fillMaxSize().padding(leftRight = 2.cssRem, topBottom = 8.cssRem) }
    Breakpoint.MD { Modifier.maxWidth(60.cssRem) }
}