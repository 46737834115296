package org.ecolution.webpage.data

data class Employee(
    val name: String,
    val email: String?,
    val profession: String,
    val imagSrc: String,
    val quote: String,
    ) {
        companion object {

            val CEO = "Geschäftsführer"
            val ENERGIEBERATER = "Energieberater"
            val ESG = "ESG Compliance"
            val ENERGIEBERATERIN = "Energieberaterin"
            val OFFICEDOG = "Bürohund"

            object Employee {
                const val RS_NAME = "Roger Sennhauser"
                const val RS_EMAIL = "mailto:roger.sennhauser@ecolution.swiss"
                const val RS_IMAGE = "employees/roger-sennhauser.png"
                const val RS_QUOTE = "Inovationen in der Energiebranche spielen eine zentrale Rolle" +
                        " für eine nachhaltige Zukunft."

                const val MA_NAME= "Marta Alzueta Català"
                const val MA_EMAIL = "mailto:marta.alzueta@ecolution.swiss"
                const val MA_IMAGE = "employees/marta-alzueta.png"
                const val MA_QUOTE = "Die Gestaltung nachhaltiger Städte und Gemeinden beginnt mit dem Engagement " +
                        "jedes Einzelnen für eine lebenswerte Zukunft."

                const val AA_NAME = "Amyne Aragão Pinto Martins"
                const val AA_EMAIL = "mailto:amyne.aragao@ecolution.swiss"
                const val AA_IMAGE = "employees/amyne-aragao.png"
                const val AA_QUOTE = "Die Transformation hin zu sauberer Energie ist nicht nur ein Ziel, " +
                        "es ist unsere Verantwortung gegenüber den kommenden Generationen."

                const val PB_NAME = "Pascal Bräm"
                const val PB_EMAIL = "mailto:pascal.braem@ecolution.swiss"
                const val PB_IMAGE = "employees/pascal-braem.png"
                const val PB_QUOTE = "Die Effizienz der Energie ist entscheidend, um den ökologischen " +
                        "Fussabdruck zu minimieren und die Klimaziele zu erreichen."

                const val KI_NAME = "Kevin Imhoff"
                const val KI_EMAIL = "mailto:kevin.imhoff@ecolution.swiss"
                const val KI_IMAGE = "employees/kevin-imhoff.png"
                const val KI_QUOTE = "Mit 15 Jahre Erfahrung bin ich bereit den " +
                        "Wandel in der Gebäudetechnik und Bauphysik voranzutreiben."

                const val NALA_NAME = "Nala"
                const val NALA_IMAGE = "employees/nala.png"
                const val NALA_QUOTE = "Wuff wuff, vergiss nicht im Moment zu leben!"

                val employees = listOf(
                    Employee(KI_NAME, KI_EMAIL, CEO, KI_IMAGE, KI_QUOTE),
                    //Employee(AA_NAME, AA_EMAIL, ENERGIEBERATERIN, AA_IMAGE, AA_QUOTE),
                    Employee(RS_NAME, RS_EMAIL, ENERGIEBERATER, RS_IMAGE, RS_QUOTE),
                    Employee(PB_NAME, PB_EMAIL, ENERGIEBERATER, PB_IMAGE, PB_QUOTE),
                    //Employee(MA_NAME, MA_EMAIL, ESG, MA_IMAGE, MA_QUOTE),
                    Employee(NALA_NAME, null, OFFICEDOG, NALA_IMAGE, NALA_QUOTE)
                )
            }
        }
    }