package org.ecolution.webpage.components.sections.service

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCalculate
import com.varabyte.kobweb.silk.components.icons.mdi.MdiQuestionAnswer
import com.varabyte.kobweb.silk.components.icons.mdi.MdiShoppingCart
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.ecolution.webpage.components.widgets.CustomButton
import org.ecolution.webpage.components.widgets.LinkButton
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*


@Composable
fun ServiceButtonSection(entries: List<Triple<String, String, Boolean>>, modifier: Modifier) {
    val backgroundColor = ColorMode.current.toSitePalette().secondaryBackground
    val borderColor = ColorMode.current.toSitePalette().navHeaderBackground

    Section(Modifier
        .fillMaxWidth()
        .padding(1.cssRem)
        .backgroundColor(backgroundColor)
        .position(Position.Sticky)
        .borderTop(width = 1.px, color = borderColor, style = LineStyle.Solid)
        .bottom(0.percent)
        .then(modifier)
    ) {
        Box {
            val color = ColorMode.current.toSitePalette().text

            SimpleGrid(numColumns = numColumns(entries.size)) {
                entries.forEach { (path, text, useAnker) ->
                    ConversionLinkButton(
                        path,
                        text,
                        useAnker
                    ) {
                        when (text) {
                            "Kosten" -> MdiCalculate(Modifier.color(color).margin(leftRight = 4.px))
                            "Bestellen" -> MdiShoppingCart(Modifier.color(color).margin(leftRight = 4.px))
                            "FAQ" -> MdiQuestionAnswer(Modifier.color(color).margin(leftRight = 4.px))
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun ConversionLinkButton(path: String, text: String, useAnker: Boolean, icon: @Composable () -> Unit) {
    if (useAnker) {
        CustomButton(
            onClick = { document.getElementById(path)?.scrollIntoView() },
            Modifier.margin(leftRight = 1.2.cssRem)
                .width(150.px)
                .alignSelf(AlignSelf.Center),
            text
        ) {
            icon()
        }
    } else {
        LinkButton(
            path,
            Modifier.margin(leftRight = 1.2.cssRem)
                .width(150.px)
                .alignSelf(AlignSelf.Center),
            text
        ) {
            icon()
        }
    }
}