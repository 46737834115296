package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.ecolution.webpage.data.Service
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img


@Composable
fun MultiMenu(
    target: ElementTarget,
    services: List<Service>,
    keepOpenStrategy: ManualKeepPopupOpenStrategy
) {
    val placementStrategy = remember(PopupPlacement.Bottom) { PopupPlacementStrategy.of(PopupPlacement.Bottom, 0) }

    AdvancedPopover(
        target,
        modifier =
            Modifier.position(Position.Sticky) // So arrow is positioned relative to tooltip area
                .backgroundColor(TooltipVars.BackgroundColor.value())
                .color(TooltipVars.Color.value())
                .borderRadius(6.px)
                .onMouseEnter { keepOpenStrategy.shouldKeepOpen = true }
                .onMouseLeave { keepOpenStrategy.shouldKeepOpen = false }
            .position(Position.Sticky) // So arrow is positioned relative to tooltip area
            .backgroundColor(TooltipVars.BackgroundColor.value())
            .color(TooltipVars.Color.value())
            .borderRadius(6.px)
            .onMouseEnter { keepOpenStrategy.shouldKeepOpen = true }
            .onMouseLeave { keepOpenStrategy.shouldKeepOpen = false },
        hiddenModifier = Modifier,
        variant = null,
        0,
        0,
        openCloseStrategy = null,
        null,
        placementStrategy,
        keepOpenStrategy,
        null
    ) {
        SimpleGrid(numColumns(2, md = 2)) {
            services.forEach { subMenu ->
                SubMenuItem(subMenu, keepOpenStrategy)
            }
        }
        Box(
            TooltipArrowStyle.toModifier(TopTooltipArrowVariant)
        )
    }
}

@Composable
private fun SubMenuItem(service: Service, keepOpenStrategy: ManualKeepPopupOpenStrategy) {
    val ctx = rememberPageContext()

    val textColor = ColorMode.current.toSitePalette().oppositeText
    val subMenuBackgroundColor = ColorMode.current.toSitePalette().secondaryBackground
    val hoverBackgroundColor = ColorMode.current.toSitePalette().navHeaderBackground.lightened()

    var isHovering by remember { mutableStateOf(false) }

    Box (
        Modifier
            .margin(10.px)
            .borderRadius(12.px)
            .background(if (isHovering) hoverBackgroundColor else subMenuBackgroundColor)
            .padding(0.5.em)
            .cursor(Cursor.Pointer)
            .onMouseEnter { isHovering = true }
            .onMouseLeave { isHovering = false }
            .onClick {
                keepOpenStrategy.shouldKeepOpen = false
                window.setTimeout({
                    ctx.router.navigateTo(service.urlPath)
                }, 500)
            }
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
        ) {
            Img(
                service.imagePath,
                service.name,
                Modifier
                    .height(3.cssRem)
                    .display(DisplayStyle.Block)
                    .toAttrs()
            )
            SpanText(
                service.name,
                Modifier
                    .fontWeight(FontWeight.Bold)
                    .margin(top = 0.75.cssRem)
                    .padding(left = 5.px)
                    .color(textColor)
            )
        }
    }
}
