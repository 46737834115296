package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.functions.RadialGradient
import com.varabyte.kobweb.compose.css.functions.radialGradient
import com.varabyte.kobweb.compose.css.textOverflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariantBase
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.FlexDirection.Companion.Column
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H4

@Composable
fun SectionTitle(title: String, subtitle: String) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        HeaderTitle(title, Modifier.margin(top = 3.em))
        SubTitle(subtitle, Modifier.margin(bottom = 2.em).then(SubTitleStyle.toModifier()))
    }
}

@Composable
fun HeaderTitle(title: String, modifier: Modifier) {
    H1(modifier.toAttrs()) {
        SpanText(
            title, variant = HeaderTitleVariant
        )
    }
}

@Composable
fun SubTitle(subtitle: String, modifier: Modifier) {
    H2(modifier.toAttrs()) {
        SpanText(
            subtitle, variant = SubTitleVariant
        )
    }
}

// For occasional text that we want to de-emphasize a bit, e.g. for subtitles.
// Note: We used to use the opacity style for this, but that caused an issue with the way blur was calculated, for the
// header glass effect, so we use alpha here instead.
val SubTitleVariant by SpanTextStyle.addVariantBase {
    Modifier.color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.7f)).display(DisplayStyle.Flex)
        .justifyContent(JustifyContent.Center).textAlign(TextAlign.Center).flexDirection(FlexDirection.Row)
}

val HeaderTitleVariant by SpanTextStyle.addVariantBase {
    Modifier.display(DisplayStyle.Flex).justifyContent(JustifyContent.Center).textAlign(TextAlign.Center)
        .flexDirection(FlexDirection.Row)
}

val CenterAlignedStyle by ComponentStyle {
    Modifier.display(DisplayStyle.Flex).justifyContent(JustifyContent.Center).textAlign(TextAlign.Center)
        .flexDirection(FlexDirection.Row)
}


val SubTitleStyle by ComponentStyle {
    base { Modifier.fillMaxSize().padding(leftRight = 2.cssRem, top = 0.5.cssRem) }
    Breakpoint.MD { Modifier.maxWidth(60.cssRem) }
}

fun Modifier.background(colorMode: ColorMode) = this.then(
    when (colorMode) {
        ColorMode.DARK -> Modifier.backgroundImage(
            radialGradient(
                RadialGradient.Shape.Circle, Color.rgb(41, 41, 46), Color.rgb(25, 25, 28), CSSPosition.Top
            )
        )

        ColorMode.LIGHT -> Modifier.backgroundColor(Colors.White)
    }
)
