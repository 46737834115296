package org.ecolution.webpage.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import org.ecolution.webpage.components.layouts.HalfPageContentStyle
import org.ecolution.webpage.components.widgets.PartnerGrid
import org.ecolution.webpage.data.Partner.Companion.Partnerships.partners
import org.jetbrains.compose.web.css.*

@Composable
fun PartnershipSection() {
    Box(modifier = HalfPageContentStyle.toModifier()) {
        SimpleGrid(numColumns(1, sm = 1, md = 2, lg = 4), Modifier
            .margin(top = 6.cssRem)
            .fillMaxWidth()
        ) {
            partners.forEach { partner ->
                PartnerGrid(partner, true)
            }
        }
    }
}

