package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.PageLayout
import org.ecolution.webpage.components.sections.BlogSection
import org.ecolution.webpage.components.sections.BlogSectionTitle
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.data.Navigation.BLOG_NAME

@Page
@Composable
fun BlogsPage() {
    PageLayout(BLOG_NAME) {
        Section {
            BlogSectionTitle()
            BlogSection()
        }
    }
}