package org.ecolution.webpage.components.layouts

import androidx.compose.runtime.*

@Composable
fun ClientSideRendering(content: @Composable () -> Unit) {
    var isClient by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        isClient = true
    }

    if (isClient) {
        content()
    }
}