package org.ecolution.webpage.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.data.Company
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Img

@Composable
fun Ecolution2050() {
    val breakpoint = rememberBreakpoint()
    val colorMode = ColorMode.current
    val filter = if (colorMode == ColorMode.DARK) "grayscale(100%) brightness(85%)" else "none"

    if (breakpoint >= Breakpoint.MD) {

        Section {
            Box(
                Modifier.width(100.percent).margin(top = 10.cssRem),
            ) {
                Img(
                    src = Company.Eco.ECOLUTION_2050,
                    alt = Company.Details.FULL_NAME,
                    attrs = Modifier
                        .styleModifier {
                            property("filter", filter)
                        }
                        .display(DisplayStyle.Block)
                        .objectFit(ObjectFit.Contain)
                        .fillMaxSize()
                        .toAttrs()
                )
            }
        }
    }
}