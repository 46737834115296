package org.ecolution.webpage.data

enum class Canton (var title: String, val code: String, val image: String){
    AG("Aargau", "AG", "cantons/aargau.png"),
    AR("Appenzell Ausserrhoden", "AR", "cantons/appenzell_aussenrrhoden.png"),
    AI("Appenzell Innerrhoden", "AI", "cantons/appenzell_inerrhoden.png"),
    BL("Basel-Landschaft", "BL", "cantons/basel_land.png"),
    BS("Basel-Stadt", "BS", "cantons/basel_stadt.png"),
    BE("Bern", "BE","cantons/bern.png"),
    FR("Freiburg", "FR", "cantons/freiburg.png"),
    GE("Genf", "GE", "cantons/genf.png"),
    GL("Glarus", "GL", "cantons/glarus.png"),
    GR("Graubünden", "GR", "cantons/graubuenden.png"),
    JU("Jura", "JU", "cantons/jura.png"),
    LU("Luzern", "LU", "cantons/luzern.png"),
    NE("Neuenburg", "NE", "cantons/neuenburg.png"),
    NW("Nidwalden", "NW", "cantons/nidwalden.png"),
    OW("Obwalden", "OW", "cantons/obwalden.png"),
    SG("St. Gallen", "SG", "cantons/st_gallen.png"),
    SH("Schaffhausen", "SH", "cantons/schaffhausen.png"),
    SZ("Schwyz", "SZ", "cantons/schwyz.png"),
    SO("Solothurn", "SO", "cantons/solothurn.png"),
    TG("Thurgau", "TG", "cantons/thurgau.png"),
    TI("Tessin", "TI", "cantons/tessin.png"),
    UR("Uri", "UR", "cantons/uri.png"),
    VD("Waadt", "VD", "cantons/waadt.png"),
    VS("Wallis", "VS", "cantons/wallis.png"),
    ZG("Zug", "ZG", "cantons/zug.png"),
    ZH("Zürich", "ZH", "cantons/zuerich.png");
}
