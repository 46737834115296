package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.PageLayout
import org.ecolution.webpage.components.sections.ReferenceSection
import org.ecolution.webpage.components.sections.ReferenceSectionTitle
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.data.Navigation.REFERENCES_NAME

@Page
@Composable
fun ReferencesPage() {
    PageLayout(REFERENCES_NAME) {
        Section {
            ReferenceSectionTitle()
            ReferenceSection()
        }
    }
}