package org.ecolution.webpage

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.*

private val PRIMARY_DARK = Color.rgb(0x282828)
private val SECONDARY_DARK = Color.rgb(0x2828280)

private val PRIMARY_LIGHT = Color.rgb(0xFAFAFA)
private val ECOLUTION = Color.rgb(0x018675)
private val SECONDARY_LIGHT = Color.rgb(0xD8D8D8)

val WHITE = Color.rgb(0xFFFFFF)
private val YELLOW = Color.rgb(0x996600)
private val PURPLE = Color.rgb(0x7030A0)
private val LIGHT_GREY = Color.rgb(0xCCCCCC)
val DARK_GREY = Color.rgb(0X666666)

class CustomSitePalette(
    val secondaryBackground: Color,
    val navHeaderBackground: Color,
    val navLinkOn: Color,
    val navLinkOff: Color,
    val action: Color,
    val faqTitle: Color,
    val faq: Color,
    val cobweb: Color,
    val brand: Brand,
    val text: Color,
    val oppositeText: Color,
    val transparentButtonHover: Color,
    val transparentButtonIcon: Color,
    val primaryButton: Color
) {
    class Brand(
        val ebfBadge: Color,
        val certificateBadge: Color,
    )
}

object SitePalettes {
    val light = CustomSitePalette(
        secondaryBackground = SECONDARY_LIGHT,
        navHeaderBackground = PRIMARY_LIGHT,
        navLinkOn = DARK_GREY.lightened(),
        navLinkOff = DARK_GREY,
        action = PURPLE,
        faqTitle = DARK_GREY,
        faq = ECOLUTION,
        cobweb = Colors.LightGray,
        text = WHITE,
        oppositeText = DARK_GREY,
        transparentButtonHover = DARK_GREY.lightened(0.5f),
        transparentButtonIcon = WHITE,
        primaryButton = ECOLUTION,
        brand = CustomSitePalette.Brand(
            ebfBadge = YELLOW,
            certificateBadge = PRIMARY_DARK,
        )
    )
    val dark = CustomSitePalette(
        secondaryBackground = SECONDARY_DARK,
        navHeaderBackground = PRIMARY_DARK,
        navLinkOn = WHITE,
        navLinkOff = WHITE.darkened().lightened(),
        action = PURPLE,
        faqTitle = WHITE,
        faq = ECOLUTION,
        cobweb = Colors.LightGray.inverted(),
        text = DARK_GREY,
        oppositeText = WHITE,
        transparentButtonHover = DARK_GREY.lightened(0.5f), // unused
        transparentButtonIcon = WHITE, // unused
        primaryButton = WHITE,
        brand = CustomSitePalette.Brand(
            ebfBadge = YELLOW,
            certificateBadge = PRIMARY_DARK,
        )
    )

}

fun ColorMode.toSitePalette(): CustomSitePalette {
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.dark
    }
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {

    ctx.theme.palettes.light.background = PRIMARY_LIGHT
    ctx.theme.palettes.light.color = DARK_GREY
    ctx.theme.palettes.light.tooltip.background = SECONDARY_LIGHT.darkened()
    ctx.theme.palettes.light.tooltip.color = PRIMARY_LIGHT
    ctx.theme.palettes.light.button.default = ECOLUTION
    ctx.theme.palettes.light.button.hover = ECOLUTION.lightened()
    ctx.theme.palettes.light.button.focus = PRIMARY_DARK.lightened()
    ctx.theme.palettes.light.button.pressed = PRIMARY_DARK.lightened(0.2f)
    ctx.theme.palettes.light.link.default = SECONDARY_DARK
    ctx.theme.palettes.light.link.visited = SECONDARY_DARK

    ctx.theme.palettes.dark.background = PRIMARY_DARK
    ctx.theme.palettes.dark.color = SitePalettes.light.text
    ctx.theme.palettes.dark.tooltip.background = PRIMARY_LIGHT
    ctx.theme.palettes.dark.tooltip.color = PRIMARY_DARK
    ctx.theme.palettes.dark.button.default = PRIMARY_LIGHT
    ctx.theme.palettes.dark.button.hover = PRIMARY_LIGHT.darkened()
    ctx.theme.palettes.dark.button.focus = PRIMARY_LIGHT.darkened()
    ctx.theme.palettes.dark.button.pressed = PRIMARY_LIGHT.darkened(0.2f)
    ctx.theme.palettes.dark.link.default = PRIMARY_LIGHT
    ctx.theme.palettes.dark.link.visited = PRIMARY_LIGHT

    ctx.theme.palettes.dark.input.filledHover = SECONDARY_DARK
    ctx.theme.palettes.dark.input.filledFocus = SECONDARY_DARK
    ctx.theme.palettes.dark.input.invalidBorder = SECONDARY_DARK
    ctx.theme.palettes.dark.input.hoveredBorder = SECONDARY_DARK

}
