package org.ecolution.webpage.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiArrowDownward
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.components.widgets.*
import org.ecolution.webpage.data.Reference
import org.ecolution.webpage.data.Reference.Companion.References.buildingRef
import org.ecolution.webpage.style.rememberHoverFilter
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

private val REFERENCECARD_BORDER_RADIUS = 5.px
private val REFERENCECARD_PADDING_BOTTOM = 1.cssRem
private val REFERENCECARD_MARGIN = 2.cssRem
private val DISPLAY_REFERENCES_LARGE_SCREEN = 9
private val DISPLAY_REFERENCES_SMALL_SCREEN = 6


@Composable
fun ReferenceSectionTitle() {
    SectionTitle(
        title = "Unsere Referenzen",
        subtitle = "Mehrjährige Erfahrung und zufriedene Kunden"
    )
}

@Composable
fun ReferenceSection() {
    val breakpoint = rememberBreakpoint()
    val (numItems, setNumItems) = remember { mutableStateOf(0) }

    updateNumItems(breakpoint, setNumItems)

    createSimpleGrid(numItems)

    if (numItems < buildingRef.size) {
        createLoadMoreButton(numItems, setNumItems)
    } else {
        SubTitle(
            "Unsere Reise zu mehr Nachhaltigkeit in der Schweiz geht weiter. Lassen Sie uns gemeinsam Ihr Gebäude energieeffizient gestalten!",
            Modifier.margin(top = 2.em).then(SubTitleStyle.toModifier())
        )
    }
}

@Composable
private fun ReferenceCard(buildingRef: Reference) {
    val color = ColorMode.current.toSitePalette().secondaryBackground
    val (filter, hoverModifier) = rememberHoverFilter()

    Box(
        modifier = Modifier
            .backgroundColor(color)
            .borderRadius(REFERENCECARD_BORDER_RADIUS)
            .padding(bottom = REFERENCECARD_PADDING_BOTTOM)
            .margin(REFERENCECARD_MARGIN)
            .then(hoverModifier)
    ) {
        Column(modifier = Modifier.margin(10.px)) {
            ReferenceImage(buildingRef, filter.value)
            createTitle(buildingRef)
            createBadges(buildingRef)
            //createDescription(buildingRef)
        }
    }
}

@Composable
fun updateNumItems(breakpoint: Breakpoint, setNumItems: (Int) -> Unit) {
    LaunchedEffect(breakpoint) {
        val numDefaultItems =
            if (breakpoint >= Breakpoint.LG) DISPLAY_REFERENCES_LARGE_SCREEN else DISPLAY_REFERENCES_SMALL_SCREEN
        setNumItems(numDefaultItems)
    }
}

@Composable
private fun createSimpleGrid(numItems: Int) {
    SimpleGrid(
        numColumns = numColumns(1, sm = 1, md = 2, lg = 2, xl = 3),
        modifier = Modifier.fillMaxWidth()
    ) {
        buildingRef.take(numItems).forEach { feature -> ReferenceCard(feature) }
    }
}

@Composable
fun createLoadMoreButton(numItems: Int, setNumItems: (Int) -> Unit) {
    val color = ColorMode.current.toSitePalette().text

    Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
        CustomButton(
            onClick = { setNumItems(numItems + DISPLAY_REFERENCES_SMALL_SCREEN) },
            modifier = Modifier.width(200.px),
            text = "Mehr laden"
        ) {
            MdiArrowDownward(Modifier.margin(right = 8.px).color(color))
        }
    }
}

@Composable
private fun createTitle(buildingRef: Reference) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.margin(left = 0.5.cssRem, right = 0.75.cssRem, top = 0.75.cssRem, bottom = 0.75.cssRem)
    ) {
        Img(
            src = buildingRef.canton.image,
            alt = buildingRef.canton.code,
            Modifier
                .height(25.px)
                .margin(right = 0.5.cssRem)
                .toAttrs()
        )
        Spacer()
        SpanText(
            buildingRef.title,
            Modifier.fontSize(20.px).fontWeight(FontWeight.Bold)
        )
    }
}

@Composable
private fun createBadges(buildingRef: Reference) {
    SimpleGrid(numColumns(3)) {
        buildingRef.certificates.forEach { certificate ->
            CertificateBadge(certificate)
        }
        EBFBadge(buildingRef.ebf)
    }
}

@Composable
private fun createDescription(buildingRef: Reference) {
    Row {
        SpanText(buildingRef.description, Modifier.padding(topBottom = 5.px, leftRight = 10.px))
    }
}

@Composable
private fun ReferenceImage(buildingRef: Reference, filter: String) {
    Img(
        src = buildingRef.imgSrc,
        alt = "",
        Modifier
            .fillMaxSize()
            .aspectRatio(1.0f, 0.7f)
            .display(DisplayStyle.Block)
            .objectFit(ObjectFit.Cover)
            .borderRadius(topRight = REFERENCECARD_BORDER_RADIUS, topLeft = REFERENCECARD_BORDER_RADIUS)
            .styleModifier {
                property("filter", filter)
            }
            .toAttrs()
    )
}

