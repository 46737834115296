package org.ecolution.webpage.style

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.silk.theme.colors.ColorMode

@Composable
fun rememberHoverFilter(
    darkFilter: String = "grayscale(85%)",
    lightFilter: String = "grayscale(95%)",
    darkHoverFilter: String = "grayscale(20%) brightness(-80%)",
    lightHoverFilter: String = "grayscale(85%) brightness(110%)"
): Pair<State<String>, Modifier> {
    val colorMode = ColorMode.current
    val initialFilter = if (colorMode == ColorMode.DARK) darkFilter else lightFilter
    val hoverFilter = if (colorMode == ColorMode.DARK) darkHoverFilter else lightHoverFilter

    val filterState = remember { mutableStateOf(initialFilter) }

    val modifier = Modifier
        .onMouseEnter {
            filterState.value = hoverFilter
        }
        .onMouseLeave {
            filterState.value = initialFilter
        }

    return filterState to modifier
}