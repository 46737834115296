package org.ecolution.webpage.data

data class Service(val name: String, val urlPath: String, val imagePath: String) {

    companion object {
        object SubNavigation {

            const val GEAK_NAME = "GEAK"
            const val GEAK_URL = "/geak"
            const val GEAK_IMG = "services/geak.svg"
            const val GEAK_ANKER = "geak-faq"

            const val GEAK_PLUS_NAME = "GEAK Plus"

            const val GA_NAME = "Gebäudeanalyse"
            const val GA_URL = "/gebaeudeanalyse"
            const val GA_IMG = "services/gebaeudeanalyse.svg"
            const val GA_ANKER = "ga-faq"

            const val EN_NAME = "Energienachweis"
            const val EN_URL = "/energienachweis"
            const val EN_IMG = "services/energienachweis.svg"
            const val EN_ANKER = "en-faq"

            const val MINERGIE_NAME = "Minergienachweis"
            const val MINERGIEN_URL = "/minergienachweis"
            const val MINERGIE_IMG = "services/minergienachweis.svg"
            const val MINERGIE_ANKER = "minergie-faq"

            const val IMPULSBERATUNG_NAME = "Impulsberatung"
            const val IMPULSBERATUNG_URL = "/impulsberatung"
            const val IMPULSBERATUNG_IMG = "services/impulsberatung.svg"
            const val IMPULSBERATUNG_ANKER = "impulsberatung-faq"

            const val SCHNECK_NAME = "Schneck"
            const val SCHNECK_URL = "/schneck"
            const val SCHNECK_IMG = "services/schneck.svg"
            const val SCHNECK_ANKER = "schneck-faq"

            val subServices = listOf(
                Service(GEAK_NAME, GEAK_URL, GEAK_IMG),
                Service(GA_NAME, GA_URL, GA_IMG),
                Service(EN_NAME, EN_URL, EN_IMG),
                Service(MINERGIE_NAME, MINERGIEN_URL, MINERGIE_IMG),
                Service(IMPULSBERATUNG_NAME, IMPULSBERATUNG_URL, IMPULSBERATUNG_IMG),
               //Service(SCHNECK_NAME, SCHNECK_URL, SCHNECK_IMG),
            )
        }
    }
}
