package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.CSSAnimation
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.*
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import org.ecolution.webpage.data.FAQ
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun AccordionItem(
    item: FAQ,
    expandedItem: String?,
    onItemClicked: (String?) -> Unit
) {
    val isExpanded = expandedItem == item.question
    val toggleItem: (SyntheticMouseEvent) -> Unit = { _ -> onItemClicked(if (isExpanded) null else item.question) }

    Box(
        modifier = Modifier
            .onClick(toggleItem)
            .cursor(Cursor.Pointer)
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .onClick(toggleItem)
                .fillMaxWidth()
        ) {
            H4(
                attrs = Modifier
                    .weight(0.8f)
                    .fillMaxWidth()
                    .padding(right = 5.px)
                    .fontWeight(FontWeight.Bold)
                    .toAttrs()
            ) {
                Text(item.question)
            }
            Box {
                if (isExpanded) {
                    MdiRemove()
                } else {
                    MdiAdd()
                }
            }
        }
    }

    if (isExpanded) {
        P(
            attrs = Modifier.overflowWrap(OverflowWrap.BreakWord).toAttrs()
        ) {
            Text(item.answer)
        }
    }
    HorizontalDivider(modifier = Modifier.fillMaxWidth())
}

