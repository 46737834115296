import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import kotlinx.browser.document

@Composable
fun MicrosoftClarity() {
    DisposableEffect(Unit) {
        val script = document.createElement("script")
        script.setAttribute("type", "text/javascript")
        script.textContent = """
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "oci5t3yjtg");
        """.trimIndent()
        document.head?.appendChild(script)
        onDispose {
            document.head?.removeChild(script)
        }
    }
}