package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.ServiceLayout
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.data.Service.Companion.SubNavigation.EN_ANKER
import org.ecolution.webpage.data.Service.Companion.SubNavigation.EN_IMG
import org.ecolution.webpage.data.Service.Companion.SubNavigation.EN_NAME

@Page
@Composable
fun EnergienachweisPage() {
    ServiceLayout(
        serviceName = EN_NAME,
        subtitle = SUBTITLE,
        imgsrc = EN_IMG,
        marketing = MARKETING,
        faqs = FAQS,
        faqAnkerId = EN_ANKER,
        buttons = BUTTONS,
        keyFacts = KEYFACTS
    )
}

private const val SUBTITLE = "nach SIA 380/1"

private val MARKETING = listOf(
    "Erfüllung von rechtlichen Standards",
    "Einfache und komplett digitale Abwicklung",
    "Garantierte Qualität und Kostentransparenz"
)

private val KEYFACTS = listOf(
    KeyFact(
        imgSrcLightMode = Company.Eco.CERTIFICATE_LIGHT,
        imgSrcDarkMode = Company.Eco.CERTIFICATE_DARK,
        title = "Energieeffizienz und Rechtskonformität",
        description = "Unser Energienachweis nach SIA 380/1 bietet eine präzise Bewertung der Energieeffizienz und stellt die Konformität mit aktuellen Standards und Gesetzen sicher. Optimieren Sie Ihr Gebäude für Nachhaltigkeit und Rechtskonformität."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.Desktop,
        imgSrcDarkMode = Company.Eco.Desktop,
        title = "Energiebedarf und Wärmedämmung",
        description = "Der Energienachweis nach SIA 380/1 beinhaltet die Berechnung des Energiebedarfs und die Bewertung der Wärmedämmung. Mit Ecolution erhalten Sie eine umfassende Analyse und Beratung zur Optimierung Ihrer Gebäudehülle."
    ),
    /*KeyFact(
        imgSrcLightMode = Company.Eco.Desktop,
        imgSrcDarkMode = Company.Eco.Desktop,
        title = "Digitale Effizienz",
        description = "Profitieren Sie von unserem vollständig digitalisierten Service. Unsere Online-Plattform ermöglicht eine schnelle und einfache Abwicklung Ihres Projekts von der Anfrage bis zum Abschluss, ganz bequem von Ihrem Computer oder Mobilgerät aus. Erleben Sie Effizienz und Komfort mit Ecolution."
    ),*/
    KeyFact(
        imgSrcLightMode = Company.Eco.Desktop,
        imgSrcDarkMode = Company.Eco.Desktop,
        title = "Komplettservice für Fördergeld-Beantragung",
        description = "Als Zusatzleistung kann Ecolution für Sie den gesamten Antragsprozess für Fördergelder " +
                "übernehmen. Wir erledigen die Beantragung und sämtliche Formalitäten, um Ihnen finanzielle " +
                "Unterstützung für Ihr Energieeffizienzprojekt zu sichern. Mit diesem Service bieten wir eine " +
                "umfassende und sorgenfreie Lösung für Ihre Investition."
    )
)

private val BUTTONS = listOf(
    //Triple("", "TBD", false),
    //Triple("", "TBD", false),
    Triple(EN_ANKER, "FAQ", true)
)

private val FAQS = listOf(
    FAQ(
        "Was ist der Energienachweis nach SIA 380/1?",
        "Der Energienachweis nach SIA 380/1 ist ein schweizerischer Standard zur Berechnung und Nachweisführung des Heizwärmebedarfs von Gebäuden. Er wird sowohl für Neubauten als auch für Umbauten verwendet."
    ),
    FAQ(
        "Warum ist ein Energienachweis nach SIA 380/1 wichtig?",
        "Mit dem Energienachweis wird nachgewiesen, dass der Energieverbrauch bei einem Neu- oder Umbau vorgeschriebene Grenzwerte nicht überschreitet. Er ist bei der Baueingabe erforderlich und wird von den zuständigen Behörden kontrolliert."
    ),
    FAQ(
        "Welche Dienstleistungen bietet Ecolution im Rahmen des Energienachweises an?",
        "Ecolution bietet umfassende Dienstleistungen für den Energienachweis an. Dazu gehören die Erstellung von Systemnachweisen und Einzelbauteilnachweisen, U-Wert Berechnungen, Wärmebrückennachweise sowie die Erstellung der erforderlichen amtlichen Formulare und der Privaten Kontrolle."
    ),
    FAQ(
        "Welche Bauteile und Anforderungen werden im Rahmen des Energienachweises betrachtet?",
        "Im Rahmen des Energienachweises werden verschiedene Bauteile wie Fenster, Türen und die Gebäudehülle hinsichtlich ihrer Wärmedämmung  bewertet. Es werden Grenzwerte für den Wärmedurchgangskoeffizienten (U-Wert) und Wärmebrücken berücksichtigt."
    ),
    FAQ(
        "Was beinhaltet der Systemnachweis und der Einzelbauteilnachweis?",
        "Der Systemnachweis bezieht sich auf das gesamte Gebäude, während der Einzelbauteilnachweis spezifische Bauteile wie Fenster oder Türen betrachtet. Beide Nachweise zielen darauf ab, die Einhaltung der energetischen Anforderungen zu demonstrieren."
    ),
    FAQ(
        "Gibt es spezielle Anforderungen für Umbauten und Sanierungen?",
        "Ja, für Umbauten und Sanierungen gelten spezifische Grenzwerte, die sich von denen für Neubauten unterscheiden. Ziel ist es, eine energetisch sinnvolle Verbesserung der Gebäudehüllen sicherzustellen."
    ),
    FAQ(
        "Wie unterstützt Ecolution im Prozess der Baueingabe?",
        "Ecolution unterstützt bei der gesamten Dokumentation und Berechnung, die für die Baueingabe erforderlich ist. Dies umfasst die  Erstellung des Energienachweises sowie die Ausarbeitung aller erforderlichen Unterlagen und Formulare."
    )
)