package org.ecolution.webpage.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.ecolution.webpage.components.sections.service.FaqSection
import org.ecolution.webpage.components.sections.service.ServiceButtonSection
import org.ecolution.webpage.components.sections.service.ServiceHeaderSection
import org.ecolution.webpage.components.sections.service.ServiceKeyfactsSection
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.components.widgets.SectionTitle

@Composable
fun ServiceLayout(
    serviceName: String,
    imgsrc: String,
    subtitle: String,
    marketing: List<String>,
    faqs: List<FAQ>,
    faqAnkerId: String,
    buttons: List<Triple<String, String, Boolean>>,
    keyFacts: List<KeyFact>
) {
    PageLayout(serviceName) {
        //ServiceHeaderSection(serviceName, subtitle, imgsrc, marketing)
        SectionTitle(
            title = serviceName,
            subtitle = subtitle
        )
        ServiceKeyfactsSection(keyFacts)
        FaqSection(faqs, serviceName, faqAnkerId)
        ServiceButtonSection(buttons, Modifier.displayIfAtLeast(Breakpoint.MD))
    }
}
