package org.ecolution.webpage.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.ecolution.webpage.components.layouts.ServiceLayout
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.FAQ
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.data.Service.Companion.SubNavigation.IMPULSBERATUNG_ANKER
import org.ecolution.webpage.data.Service.Companion.SubNavigation.IMPULSBERATUNG_IMG
import org.ecolution.webpage.data.Service.Companion.SubNavigation.IMPULSBERATUNG_NAME

@Page
@Composable
fun ImpulsberatungPage() {
    ServiceLayout(
        serviceName = IMPULSBERATUNG_NAME,
        subtitle = SUBTITLE,
        imgsrc = IMPULSBERATUNG_IMG,
        marketing = MARKETING,
        faqs = FAQS,
        faqAnkerId = IMPULSBERATUNG_ANKER,
        buttons = BUTTONS,
        keyFacts = KEYFACTS
    )
}

private const val SUBTITLE = "Der Heizungscheck für Wohnen EFH | MFH"

private val MARKETING = listOf(
    "TBD",
    "TBD",
    "TBD"
)

private val KEYFACTS = listOf(
    KeyFact(
        imgSrcLightMode = Company.Eco.TALK_LIGHT,
        imgSrcDarkMode = Company.Eco.TALK_DARK,
        title = "Kostenlose Beratung",
        description = "Nutzen Sie unsere kostenlose Beratung zur Heizungssanierung und erhöhen Sie die Energieeffizienz Ihres Gebäudes. Profitieren Sie von niedrigeren Betriebskosten und machen Sie Ihre Immobilie attraktiver für umweltbewusste Käufer und Mieter. Eine Investition, die sich durch Einsparungen und Wertsteigerung auszahlt."
    ),  
        KeyFact(
        imgSrcLightMode = Company.Eco.ENERGY_LIGHT,
        imgSrcDarkMode = Company.Eco.ENERGY_DARK,
        title = "Vor-Ort-Begehung",
        description = "Unsere Experten besuchen Sie vor Ort, um individuelle Lösungen für erneuerbare Heizsysteme Ihres Gebäudes zu diskutieren."
    ),
    KeyFact(
        imgSrcLightMode = Company.Eco.MAP_LIGHT,
        imgSrcDarkMode = Company.Eco.MAP_DARK,
        title = "EnergieSchweiz unterstützt",
        description = "Das Programm wird von EnergieSchweiz gefördert und zielt auf den Umstieg auf erneuerbare Energie."
    )
)

private val BUTTONS = listOf(
    //Triple("", "TBD", false),
    //Triple("", "TBD", false),
    Triple(IMPULSBERATUNG_ANKER, "FAQ", true)
)

private val FAQS = listOf(
    FAQ(
        "Was ist die Impulsberatung von Ecolution?",
        "Eine kostenlose, unabhängige Vor-Ort-Beratung für Gebäudeeigentümer, um den optimalen Wechsel zu erneuerbaren Heizsystemen zu planen."
    ),
    FAQ(
        "Wer kann von der Impulsberatung profitieren?",
        "Eigentümer von Liegenschaften im Kanton Aargau erhalten die Beratung kostenlos. Für Nachbarkantone fallen Anfahrtsspesen an."
    ),
    FAQ(
        "Welche Vorteile bietet die Impulsberatung?",
        "Individuelle und neutrale Beratung durch Experten mit langjähriger Erfahrung und regelmässiger Weiterbildung."
    ),
    FAQ(
        "Wie läuft die Impulsberatung ab?",
        "Anmeldung über die Website oder Telefon, Terminvereinbarung, Vor-Ort-Besichtigung und Beratung, Ausarbeitung und Zusendung eines detaillierten Berichts."
    ),
    FAQ(
        "Was wird in der Beratung besprochen?",
        "Mögliche erneuerbare Heizsysteme, Investitionskosten, Förderbeiträge, Einsparpotenzial und nächste Schritte zur Heizungssanierung."
    )
)