package org.ecolution.webpage.components.sections.service

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.components.layouts.FullPageContentStyle
import org.ecolution.webpage.components.widgets.Section
import org.ecolution.webpage.data.KeyFact
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun ServiceKeyfactsSection(keyFacts: List<KeyFact>) {
    val colorMode = ColorMode.current.toSitePalette().secondaryBackground
    val breakpoint = rememberBreakpoint()

    val resize = if (breakpoint >= Breakpoint.MD) {
        60.percent
    } else {
        85.percent
    }

    Section(
        Modifier
            .backgroundColor(colorMode)
            .borderRadius(10.px)
            .width(resize)
            .border(
                width = 1.px,
                color = colorMode,
                style = LineStyle.Solid,
            )
            .margin(topBottom = 5.px)
    ) {
        ServiceKeyfact(keyFacts)
    }
}

@Composable
private fun ServiceKeyfact(keyFacts: List<KeyFact>) {
    val breakpoint = rememberBreakpoint()

    Box(modifier = FullPageContentStyle.toModifier()) {
        SimpleGrid(
            numColumns = numColumns(1)
        ) {
            keyFacts.forEachIndexed { index, keyFact ->
                if (breakpoint >= Breakpoint.MD) {
                    ServiceKeyfactLargeScreen(keyFact)
                    if (index != keyFacts.lastIndex) {
                        HorizontalDivider()
                    }
                } else {
                    ServiceKeyfactSmallScreen(keyFact)
                    if (index != keyFacts.lastIndex) {
                        HorizontalDivider()
                    }
                }
            }
        }
    }
}

@Composable
private fun ServiceKeyfactLargeScreen(keyFact: KeyFact) {
    val colorMode by ColorMode.currentState

    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.padding(5.px).fillMaxWidth()
    ) {
        Img(
            src = getImageSource(colorMode, keyFact),
            alt = keyFact.title,
            attrs = Modifier.size(120.px).margin(right = 30.px).toAttrs()
        )
        Box(modifier = Modifier.margin(right = 10.px).fillMaxWidth()) {
            Column {
                H4(
                    attrs = Modifier
                        .fillMaxWidth()
                        .fontWeight(FontWeight.Bold)
                        .toAttrs()
                ) {
                    Text(keyFact.title)
                }
                SpanText(keyFact.description, Modifier.fillMaxWidth())
            }
        }
    }
}

@Composable
private fun ServiceKeyfactSmallScreen(keyFact: KeyFact) {
    val colorMode by ColorMode.currentState

    SimpleGrid(numColumns = numColumns(1), modifier = Modifier) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Img(
                src = getImageSource(colorMode, keyFact),
                alt = keyFact.title,
                attrs = Modifier.size(120.px).margin(bottom = 20.px).toAttrs()
            )
            Box(
                modifier = Modifier.fillMaxWidth().padding(bottom = 5.px),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                    H4(
                        attrs = Modifier
                            .fontWeight(FontWeight.Bold)
                            .textAlign(TextAlign.Center)
                            .toAttrs()
                    ) {
                        SpanText(keyFact.title)
                    }
                    SpanText(
                        keyFact.description,
                        Modifier.textAlign(TextAlign.Center)
                    )
                }
            }
        }
    }
}

private fun getImageSource(colorMode: ColorMode, keyFact: KeyFact) = when (colorMode) {
    ColorMode.DARK -> keyFact.imgSrcDarkMode
    ColorMode.LIGHT -> keyFact.imgSrcLightMode
}